import React, { useState, useCallback, useContext, useEffect, Fragment, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { AddressText, PermissionedContainer, StatusBadge } from "components";
import { useCustomToast, useNewTabNavigate, useQueryParams } from "hooks";
import { translator, currency, percent, api, EventEmitter, locale } from "lib";
import { messages } from "consts";
import { HiBolt, HiBoltSlash, HiOutlineBolt } from "react-icons/hi2";
import { TbExternalLink, TbFileInvoice, TbHomeBolt } from "react-icons/tb";
import { MdEdit, MdOnlinePrediction, MdOutlineDateRange, MdOutlineDelete, MdRefresh, MdVisibility } from "react-icons/md";
import { IoPieChartOutline } from "react-icons/io5";
import Details from "./details";
import CustomersDetailsContext from "../context";
import ConsumerUnitsContext from "./context";
import Credits from "./credits";
import { PiDivide } from "react-icons/pi";
import { BiSupport } from "react-icons/bi";
import PredictedBalances from "./predictedBalances";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const ConsumerUnits = () => {
  const { _id } = useParams();
  const { setCounters, setIsLoading, consumerUnits, isLoadingConsumerUnits, refreshConsumerUnits } = useContext(CustomersDetailsContext);
  const [editSelected, setEditSelected] = useState();
  const [deleteSelected, setDeleteSelected] = useState();
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const toast = useCustomToast();
  const newTabNavigate = useNewTabNavigate();
  const consumerUnitsRefs = useRef([]);
  const { queryParams, setQueryParams } = useQueryParams();

  useEffect(() => {
    if (queryParams.consumer_unit && consumerUnits?.data) {
      const index = _.findIndex(consumerUnits?.data, (o) => o._id === queryParams.consumer_unit);
      consumerUnitsRefs.current[index]?.scrollIntoView({ behavior: "smooth", block: "start" });
      setQueryParams(({ consumer_unit, ...rest }) => ({ ...rest }), { replace: true });
    }
  }, [queryParams, consumerUnits?.data]);

  useEffect(() => {
    setCounters((state) => ({ ...state, consumerUnits: consumerUnits?.size }));
  }, [consumerUnits?.size]);

  useEffect(() => {
    setIsLoading((state) => ({ ...state, consumerUnits: isLoadingConsumerUnits }));
  }, [isLoadingConsumerUnits]);

  useEffect(() => {
    const listener = EventEmitter.addListener("consumerUnits.refresh", refreshConsumerUnits);
    return () => listener.remove();
  }, [refreshConsumerUnits]);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete(`/private/customers/${_id}/consumer-units/${deleteSelected._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refreshConsumerUnits();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      setDeleteSelected();
    }
  }, [toast, deleteSelected?._id, refreshConsumerUnits]);

  return (
    <ConsumerUnitsContext.Provider value={{ editSelected, setEditSelected }}>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(consumerUnits?.data)} registros
        </Text>
        <IconButton
          size="sm"
          variant="outline"
          icon={<Icon as={MdRefresh} />}
          isLoading={isLoadingConsumerUnits}
          onClick={refreshConsumerUnits}
        />
        <PermissionedContainer required="customers.consumerUnits.create">
          <Tooltip
            placement="left"
            isDisabled={_id}
            shouldWrapChildren
            label="Antes de adicionar unidades consumidoras você deve salvar o cliente atual."
          >
            <Button size="sm" colorScheme="main" isDisabled={!_id} onClick={() => setEditSelected({})}>
              incluir unidade
            </Button>
          </Tooltip>
        </PermissionedContainer>
      </HStack>

      <VStack align="stretch">
        {_.map(consumerUnits?.data, (item, index) => (
          <Box key={item._id} ref={(ref) => (consumerUnitsRefs.current[index] = ref)} borderWidth="1px" borderRadius="lg">
            {item.sentToCollectionAgency && (
              <Box p="10px" pb="0">
                <HStack bg="purple.100" p="15px" borderRadius="lg">
                  <Center bg="purple.500" w="40px" h="40px" borderRadius="full" color="white">
                    <Icon as={BiSupport} boxSize="25px" />
                  </Center>
                  <Box flex="1">
                    <Heading size="sm">UC em cobrança terceirizada</Heading>
                    <Text fontSize="xs">
                      Esta UC foi enviada para cobrança terceirizada em{" "}
                      {moment(item.sentToCollectionAgencyAt).format("DD/MM/YYYY [às] HH:mm:ss")} por{" "}
                      {item.sentToCollectionAgencyBy?.name || "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            )}

            <Grid templateColumns="repeat(12, 1fr)" gap={4} p="20px">
              <GridItem as={HStack} colSpan={{ base: 12, lg: 6 }}>
                <Center bg={item.isActive ? "main.100" : "gray.100"} w="45px" h="45px" borderRadius="full">
                  <Icon as={item.isActive ? HiBolt : HiBoltSlash} color={item.isActive ? "main.500" : "gray.500"} />
                </Center>
                <Box flex="1">
                  <HStack>
                    <StatusBadge {...item.status} />
                    <StatusBadge {...item.substatus} />
                  </HStack>
                  <Heading size="md">{item.cemigInstallationNumber}</Heading>
                  <AddressText address={item.address} />
                </Box>
              </GridItem>
              <GridItem as={HStack} justifyContent={{ base: "center", lg: "flex-end" }} colSpan={{ base: 12, lg: 6 }}>
                <PermissionedContainer required="powerManagement.read">
                  <Button
                    size="sm"
                    variant="outline"
                    leftIcon={<Icon as={HiOutlineBolt} />}
                    rightIcon={<Icon as={TbExternalLink} />}
                    onClick={() => newTabNavigate("/power-management", { installationNumber: item.cemigInstallationNumber })}
                  >
                    gestão de energia
                  </Button>
                </PermissionedContainer>
                <PermissionedContainer required="invoices.read">
                  <Button
                    size="sm"
                    variant="outline"
                    leftIcon={<Icon as={TbFileInvoice} />}
                    rightIcon={<Icon as={TbExternalLink} />}
                    onClick={() => newTabNavigate("/invoices", { installationNumber: item.cemigInstallationNumber })}
                  >
                    faturas ({item.invoicesCount?.toLocaleString() || 0})
                  </Button>
                </PermissionedContainer>

                <Button size="sm" rightIcon={<Icon as={MdEdit} />} variant="outline" onClick={() => setEditSelected(item)}>
                  detalhamento
                </Button>
                <PermissionedContainer required="customers.consumerUnits.delete">
                  <Button size="sm" rightIcon={<Icon as={MdOutlineDelete} />} variant="outline" onClick={() => setDeleteSelected(item)}>
                    excluir
                  </Button>
                </PermissionedContainer>
              </GridItem>
            </Grid>

            <PermissionedContainer
              required={["customers.consumerUnits.credits.trader.read", "customers.consumerUnits.credits.distributor.read"]}
            >
              <Divider />
              <Box p="10px" opacity={item.isActive ? 1 : 0.5}>
                <Grid templateColumns="repeat(12, 1fr)" gap={2}>
                  {["trader", "distributor"].map((entityType) => (
                    <GridItem colSpan={{ base: 12, lg: 6 }}>
                      <PermissionedContainer required={`customers.consumerUnits.credits.${entityType}.read`}>
                        <Credits {...{ entityType, consumerUnit: item }} />
                      </PermissionedContainer>
                    </GridItem>
                  ))}
                </Grid>
              </Box>
            </PermissionedContainer>

            {item.currentElectricityBalance && (
              <PermissionedContainer required="customers.consumerUnits.readElectricityBalance">
                <Box mx="10px" p="5px" borderRadius="lg" opacity={item.isActive ? 1 : 0.5} bg="green.500" color="white">
                  <Grid templateColumns="repeat(5, 1fr)" gap={2} mb={2}>
                    <GridItem
                      colSpan={{ base: 5, lg: 1 }}
                      as={HStack}
                      justifyContent="center"
                      bg="whiteAlpha.100"
                      p="10px"
                      borderRadius="lg"
                    >
                      <Center bg="green.600" w="40px" h="40px" borderRadius="full">
                        <Icon as={MdOutlineDateRange} />
                      </Center>
                      <Box>
                        <Text fontSize="xs">Referência</Text>
                        <Text fontSize="sm" fontWeight="semibold">
                          {moment(item.currentElectricityBalance.referenceDate).format("MMM/YYYY").toUpperCase()}
                        </Text>
                      </Box>
                    </GridItem>
                    <GridItem
                      colSpan={{ base: 5, lg: 1 }}
                      as={HStack}
                      justifyContent="center"
                      bg="whiteAlpha.100"
                      p="10px"
                      borderRadius="lg"
                    >
                      <Center bg="green.600" w="40px" h="40px" borderRadius="full">
                        <Icon as={HiOutlineBolt} />
                      </Center>
                      <Box>
                        <Text fontSize="xs">Saldo de energia</Text>
                        <Text fontSize="sm" fontWeight="semibold">
                          {locale(item.currentElectricityBalance.currentPowerQttBalanceInKwh || 0, { precision: 0 })} kWh
                        </Text>
                      </Box>
                    </GridItem>
                    <GridItem
                      colSpan={{ base: 5, lg: 1 }}
                      as={HStack}
                      justifyContent="center"
                      bg="whiteAlpha.100"
                      p="10px"
                      borderRadius="lg"
                    >
                      <Center bg="green.600" w="40px" h="40px" borderRadius="full">
                        <Icon as={IoPieChartOutline} />
                      </Center>
                      <Box>
                        <Text fontSize="xs">Quota</Text>
                        <Text fontSize="sm" fontWeight="semibold">
                          {percent(item.currentElectricityBalance.quota, { precision: 5 })}
                        </Text>
                      </Box>
                    </GridItem>
                    <GridItem
                      colSpan={{ base: 12, lg: 2 }}
                      justifyContent="center"
                      bg="whiteAlpha.100"
                      p="15px"
                      borderRadius="lg"
                      position="relative"
                    >
                      <HStack>
                        <Center bg="green.600" w="40px" h="40px" borderRadius="full">
                          <Icon as={TbHomeBolt} />
                        </Center>
                        <Box flex="1">
                          <Text fontSize="xs" mb={1}>
                            Últimas quotas
                          </Text>
                          <HStack spacing={3}>
                            {_(item.consumerUnitQuotas)
                              .map((item) => (
                                <HStack
                                  key={item.sentAt.toString()}
                                  spacing={1}
                                  justifyContent="center"
                                  py="5px"
                                  borderWidth="1px"
                                  flex="1"
                                  borderColor="blackAlpha.200"
                                  borderRadius="md"
                                  _hover={{ bg: "blackAlpha.200" }}
                                >
                                  <Text fontSize="xs">{item.sentAt ? moment(item.sentAt).format("MM/YYYY") : "-"}</Text>
                                  <Text fontSize="xs" fontWeight="bold" bg="green.600" px={1} borderRadius="sm">
                                    {percent(item.quota || 0, { precision: 5 })}
                                  </Text>
                                </HStack>
                              ))
                              .reverse()
                              .value()}
                          </HStack>
                        </Box>
                      </HStack>
                      <Tooltip label="Últimos envios de quotas importados." placement="left">
                        <IconButton
                          size="xs"
                          position="absolute"
                          right="5px"
                          top="5px"
                          colorScheme="whiteAlpha"
                          icon={<Icon as={AiOutlineQuestionCircle} />}
                        />
                      </Tooltip>
                    </GridItem>
                  </Grid>

                  <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={2}>
                    <GridItem
                      colSpan={{ base: 5, lg: 6 }}
                      as={HStack}
                      justifyContent="center"
                      bg="whiteAlpha.100"
                      p="10px"
                      borderRadius="lg"
                    >
                      <Center bg="green.600" w="40px" h="40px" borderRadius="full">
                        <Icon as={IoPieChartOutline} />
                      </Center>
                      <Box pr={2}>
                        <Text fontSize="xs">Saldo titularidade anterior</Text>
                        <Text fontSize="sm" fontWeight="semibold">
                          {locale(item.otherElectricityBalance.currentPowerQttBalanceInKwh || 0, { precision: 0 })} kWh
                        </Text>
                      </Box>
                      <Popover size="lg" placement="left">
                        <PopoverTrigger>
                          <IconButton size="xs" colorScheme="green" icon={<Icon as={MdVisibility} />} />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverBody p="10px" as={VStack} alignItems="stretch" spacing={1} _light={{ color: "gray.900" }}>
                            <HStack>
                              <Text fontSize="xs">Referência</Text>
                              <Divider flex="1" />
                              <Text fontSize="xs" fontWeight="semibold">
                                {item.otherElectricityBalance?.referenceDate
                                  ? moment(item.otherElectricityBalance?.referenceDate).format("MMM/YYYY").toUpperCase()
                                  : "-"}
                              </Text>
                            </HStack>
                            <HStack>
                              <Text fontSize="xs">Saldo de energia</Text>
                              <Divider flex="1" />
                              <Text fontSize="xs" fontWeight="semibold">
                                {locale(item.otherElectricityBalance?.currentPowerQttBalanceInKwh || 0, { precision: 0 })} kWh
                              </Text>
                            </HStack>
                            <HStack>
                              <Text fontSize="xs">Quota</Text>
                              <Divider flex="1" />
                              <Text fontSize="xs" fontWeight="semibold">
                                {percent(item.otherElectricityBalance?.quota, { precision: 5 })}
                              </Text>
                            </HStack>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                      <Tooltip label="Saldos retirados de faturas que não possuem o 'Número de Cliente' atual.">
                        <IconButton size="xs" colorScheme="whiteAlpha" icon={<Icon as={AiOutlineQuestionCircle} />} />
                      </Tooltip>
                    </GridItem>

                    <PredictedBalances consumerUnit={item} />
                  </Grid>

                  <Grid templateColumns="repeat(12, 1fr)" gap={2}>
                    <GridItem colSpan={{ base: 12, lg: 9 }} justifyContent="center" bg="whiteAlpha.100" p="15px" borderRadius="lg">
                      <HStack>
                        <Center bg="green.600" w="40px" h="40px" borderRadius="full">
                          <Icon as={TbHomeBolt} />
                        </Center>
                        <Box flex="1">
                          <Text fontSize="xs" mb={1}>
                            Últimos consumos
                          </Text>
                          <HStack spacing={3}>
                            {_.map(
                              item.lastsElectricityConsumes,
                              (item, index) =>
                                index <= 3 && (
                                  <HStack
                                    spacing={1}
                                    justifyContent="center"
                                    py="5px"
                                    borderWidth="1px"
                                    flex="1"
                                    borderColor="blackAlpha.200"
                                    borderRadius="md"
                                    _hover={{ bg: "blackAlpha.200" }}
                                  >
                                    <Text fontSize="xs">{moment(item.referenceDate).format("MM/YYYY")}</Text>
                                    <Text fontSize="xs" fontWeight="bold" bg="green.600" px={1} borderRadius="sm">
                                      {locale(item.consumedPowerQttInKwh || 0, { precision: 0 })} kWh
                                    </Text>
                                  </HStack>
                                )
                            )}
                          </HStack>
                        </Box>
                      </HStack>
                    </GridItem>
                    <GridItem
                      colSpan={{ base: 12, lg: 3 }}
                      as={HStack}
                      justifyContent="center"
                      bg="whiteAlpha.100"
                      p="10px"
                      borderRadius="lg"
                    >
                      <Center bg="green.600" w="40px" h="40px" borderRadius="full">
                        <Icon as={PiDivide} />
                      </Center>
                      <Box>
                        <Text fontSize="xs">Méd. últ. {_.size(item.lastsElectricityConsumes)} consumos</Text>
                        <Text fontSize="sm" fontWeight="semibold">
                          {locale(item.avgLastsElectricityConsumes || 0, { precision: 0 })} kWh
                        </Text>
                      </Box>
                    </GridItem>
                  </Grid>
                </Box>
              </PermissionedContainer>
            )}

            <Box p="20px" opacity={item.isActive ? 1 : 0.5}>
              <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Portabilidade?</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.isPortability ? "Sim" : "Não"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Dia de vencimento da fatura</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.invoiceDueDay}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Valor da conta</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {currency(item.electricityBillAmount)}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Média do contrato</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {parseInt(item.electricityAverageSpentInKwh).toLocaleString()} kWh
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Saldo atual de geração</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {parseInt(item.currentGenerationBalanceInKwh).toLocaleString()} kWh
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Geração própria</Text>
                  {item.isOwnGeneration ? (
                    <Text fontSize="sm" fontWeight="semibold">
                      Sim - {parseInt(item.ownGenerationInKwh || 0).toLocaleString()} kWh
                    </Text>
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold">
                      Não
                    </Text>
                  )}
                </GridItem>

                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Segmento</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.segment?.title ?? "-"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Tipo de instalação</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.installationType.toUpperCase()}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Classe do cliente</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.customerClass.toUpperCase()}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Dia aprox. de leitura</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.approximateReadingDay || "-"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Desconto acordado</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {percent(item.agreedDiscount)}
                  </Text>
                </GridItem>

                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Necessária troca de titularidade</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.isChangeOwnershipNecessary ? "Sim" : "Não"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Tensão da unidade</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {translator(item.voltage)}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Usina</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.powerPlant?.name ?? "-"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Direitos econômicos</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {percent(item.economicRights, { precision: 5 })}
                  </Text>
                </GridItem>

                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Tempo de fidelidade</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.loyaltyTimeInMonths || "-"} meses
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Forma de pagamento atual na CEMIG</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {translator(item.cemigPaymentMethod) || "-"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Como deseja receber a fatura?</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {translator(item.invoiceReceiptMethod) || "-"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Grupo tarifário</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.tariffGroup?.title || "-"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Grupo de vencimento</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.expirationGroup?.title || "-"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Grupo de envio</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.sendingGroup?.title || "-"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Promoção</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.promotion?.title || "-"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Modalidade de fatura</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.isUnifiedInvoice ? "Fatura unificada" : "Fatura separada"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <Text fontSize="xs">Baixa renda?</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.isLowIncome ? "Sim" : "Não"}
                  </Text>
                </GridItem>
              </Grid>
            </Box>
          </Box>
        ))}
      </VStack>

      {isLoadingConsumerUnits ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(consumerUnits?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={TbHomeBolt} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma unidade consumidora adicionada
              </Text>
              <Text fontSize="sm">Este cliente ainda não possui unidades consumidoras adicionadas.</Text>
            </Box>
          </Center>
        )
      )}

      <Details />

      <AlertDialog isOpen={deleteSelected} onClose={() => setDeleteSelected()} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir a unidade consumidora selecionada?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" variant="outline" onClick={() => setDeleteSelected()}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="red" isLoading={isLoadingDeleteData} onClick={handleDeleteData}>
              excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </ConsumerUnitsContext.Provider>
  );
};

export default ConsumerUnits;
