import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { MdAdd, MdClose, MdRefresh } from "react-icons/md";
import { CheckboxBody, CheckboxHeader, CheckboxProvider, PermissionedContainer, StatusBadge, TableEmpty } from "components";
import { useCustomToast, useFetchData } from "hooks";
import { api, currency, percent } from "lib";
import { TbExternalLink } from "react-icons/tb";
import InstallmentsDetailsContext from "./context";

export const InvoicesSelect = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { formData, setFormData, isChangeable } = useContext(InstallmentsDetailsContext);
  const [checkeds, setCheckeds] = useState([]);
  const [isLoadingCancelData, setIsLoadingCancelData] = useState(false);
  const { isOpen: isOpenCancelDialog, onOpen: onOpenCancelDialog, onClose: onCloseCancelDialog } = useDisclosure();
  const [invoices, isLoadingInvoices, refreshInvoices] = useFetchData(
    useMemo(() => {
      const query = {
        customer: { _id: formData.customer?._id },
        consumerUnit: { _id: formData.consumerUnit?._id },
        invoice: { status: { $in: ["validated", "created", "updated"] } },
      };
      return {
        path: "/private/invoices",
        params: { query },
        options: { isEnabled: isOpen && _.isObject(formData.customer) },
      };
    }, [isOpen, formData.customer])
  );
  const validatedCheckeds = useMemo(() => checkeds.filter((o) => o.status !== "validated"), [checkeds]);
  const createdOrUpdatedCheckeds = useMemo(() => checkeds.filter((o) => ["created", "updated"].includes(o.status)), [checkeds]);
  const toast = useCustomToast();

  const handleSubmit = useCallback(() => {
    setFormData((state) => ({
      ...state,
      invoices: _.map(checkeds, (o) => ({
        ...o,
        penaltyAmount: o.predictedPenaltyAmount,
        arrearsAmount: o.predictedArrearsAmount,
        arrearsPercentage: o.predictedArrearsPercentage,
        monetaryCorrectionAmount: o.predictedMonetaryCorrectionAmount,
        monetaryCorrectionPercentage: o.predictedMonetaryCorrectionPercentage,
      })),
    }));
    setCheckeds([]);
    onClose();
  }, [checkeds]);

  const handleCancelData = useCallback(async () => {
    try {
      setIsLoadingCancelData(true);
      const promises = checkeds.map((checked) => api.delete(`/private/charges/${checked.charge._id}`));
      await Promise.allSettled(promises);
      setCheckeds([]);
      refreshInvoices();
      onCloseCancelDialog();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingCancelData(false);
    }
  }, [checkeds]);

  return (
    <Fragment>
      {isChangeable && (
        <Tooltip
          label="Antes de adicionar as faturas você deve selecionar o cliente."
          placement="left"
          isDisabled={_.isObject(formData.customer)}
        >
          <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdAdd} />} onClick={onOpen} isDisabled={!_.isObject(formData.customer)}>
            selecionar faturas
          </Button>
        </Tooltip>
      )}
      <Modal
        size="6xl"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        scrollBehavior="inside"
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack}>
            <Box flex="1">
              <Text fontSize="md">Faturas</Text>
              <Text fontSize="sm" fontWeight="light">
                Selecione as faturas que deverão fazer parte deste parcelamento.
              </Text>
            </Box>
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={MdRefresh} />}
              isLoading={isLoadingInvoices}
              onClick={refreshInvoices}
            />
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody>
            <CheckboxProvider values={checkeds} onChange={setCheckeds}>
              <Table size="sm" whiteSpace="nowrap">
                <Thead>
                  <Tr>
                    <Th>
                      <CheckboxHeader />
                    </Th>
                    <Th>Nid</Th>
                    <Th>Status</Th>
                    <Th>Cod. referência</Th>
                    <Th>N° instalação</Th>
                    <Th>Dt. vencimento</Th>
                    <Th>Tempo em atraso</Th>
                    <Th>Multa</Th>
                    <Th>Juros</Th>
                    <Th>Correção IPCA</Th>
                    <Th>Valor Click</Th>
                    <Th>Total</Th>
                    <Th>#</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {_.map(invoices?.data, (item) => (
                    <Tr key={item._id}>
                      <Td>
                        <CheckboxBody value={item} />
                      </Td>
                      <Td>{item.nid}</Td>
                      <Td>
                        <StatusBadge schema="invoices" status={item.status} />
                      </Td>
                      <Td>{item.referenceCode}</Td>
                      <Td>{item.consumerUnit?.cemigInstallationNumber || "-"}</Td>
                      <Td>{moment(item.dueDate).format("DD/MM/YYYY")}</Td>
                      <Td>{item.overdueDays?.toLocaleString()} dias</Td>
                      <Td>
                        <HStack spacing={1}>
                          <Text>{currency(item.predictedPenaltyAmount)}</Text>
                          <Badge>2%</Badge>
                        </HStack>
                      </Td>
                      <Td>
                        <HStack spacing={1}>
                          <Text>{currency(item.predictedArrearsAmount)}</Text>
                          <Badge>{percent(item.predictedArrearsPercentage)}</Badge>
                        </HStack>
                      </Td>
                      <Td>
                        <HStack spacing={1}>
                          <Text>{currency(item.predictedMonetaryCorrectionAmount)}</Text>
                          <Badge>{percent(item.predictedMonetaryCorrectionPercentage)}</Badge>
                        </HStack>
                      </Td>
                      <Td>{currency(item.amount)}</Td>
                      <Td>
                        {currency(
                          item.amount + item.predictedPenaltyAmount + item.predictedArrearsAmount + item.predictedMonetaryCorrectionAmount
                        )}
                      </Td>
                      <Td>
                        <IconButton
                          size="sm"
                          variant="outline"
                          as={RouterLink}
                          target="_blank"
                          to={`/invoices/details/${item._id}`}
                          icon={<Icon as={TbExternalLink} />}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {isLoadingInvoices && (
                <Center p="30px">
                  <Spinner />
                </Center>
              )}
              <TableEmpty isLoading={isLoadingInvoices} size={_.size(invoices?.data)} />
            </CheckboxProvider>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="space-between">
            <HStack>
              <PermissionedContainer required="charges.cancel">
                <Button
                  size="sm"
                  variant="outline"
                  leftIcon={<Icon as={MdClose} />}
                  isDisabled={createdOrUpdatedCheckeds.length === 0}
                  onClick={onOpenCancelDialog}
                >
                  cancelar cobranças
                </Button>
              </PermissionedContainer>
            </HStack>
            <HStack>
              <Button size="sm" variant="outline" onClick={onClose}>
                fechar
              </Button>
              <Button
                size="sm"
                colorScheme="main"
                onClick={handleSubmit}
                isDisabled={checkeds.length === 0 || validatedCheckeds.length >= 1}
              >
                confirmar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog isOpen={isOpenCancelDialog} onClose={onCloseCancelDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent onDoubleClick={(e) => e.stopPropagation()}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancelar cobranças
            </AlertDialogHeader>
            <AlertDialogBody>Deseja realmente cancelar as cobranças selecionadas?</AlertDialogBody>
            <AlertDialogFooter as={HStack} justifyContent="flex-end">
              <Button size="sm" variant="outline" onClick={onCloseCancelDialog}>
                cancelar
              </Button>
              <Button size="sm" colorScheme="red" isLoading={isLoadingCancelData} onClick={handleCancelData}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Fragment>
  );
};
