import moment from "moment";
import _ from "lodash";
import { translator } from "lib";
import { Link as RouterLink } from "react-router-dom";
import { HStack, Link, Text } from "@chakra-ui/react";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "type",
    title: "Tipo",
    isVisible: true,
    formatter: ({ type }) => translator(type),
  },
  {
    accessor: "customer.name",
    title: "Cliente",
    isVisible: true,
    formatter: ({ customer }) => customer?.name,
  },
  {
    accessor: "consumerUnits",
    title: "Un. consumidoras",
    isVisible: true,
    render: ({ customer, consumerUnits }) => (
      <HStack divider={<Text pr="4px">,</Text>}>
        {_.map(consumerUnits, (consumerUnit) => (
          <Link as={RouterLink} to={`/customers/edit/${customer._id}?consumer_unit=${consumerUnit._id}`} target="_blank">
            {consumerUnit.cemigInstallationNumber}
          </Link>
        ))}
      </HStack>
    ),
    exporter: ({ consumerUnits }) => _.map(consumerUnits, "cemigInstallationNumber").join(", "),
  },
  {
    accessor: "invoices",
    title: "Faturas",
    isVisible: true,
    render: ({ invoices }) => (
      <HStack divider={<Text pr="4px">,</Text>}>
        {_.map(invoices, (invoice) => (
          <Link as={RouterLink} to={`/invoices/details/${invoice._id}`} target="_blank">
            #{invoice.nid}
          </Link>
        ))}
      </HStack>
    ),
    exporter: ({ invoices }) => _.map(invoices, "nid").join(", "),
  },
  {
    accessor: "messagedAt",
    title: "Dt. último envio",
    isVisible: true,
    formatter: ({ messagedAt }) => (messagedAt ? moment(messagedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
