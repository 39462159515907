import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Select,
  SlideFade,
  Spinner,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { AsyncSelect, BoxData, Breadcrumb, CustomTab, PermissionedContainer, StatusBadge, SyncSelect } from "components";
import { DocumentHistory } from "containers";
import { useFetchData, useCustomToast, useDocumentTitle } from "hooks";
import { api, masks, yup } from "lib";
import { messages } from "consts";
import { MdChevronLeft, MdDownload, MdHistory, MdRefresh } from "react-icons/md";
import { Content } from "pages/Private/Container";
import DemandLettersDetailsContext from "./context";
import Invoices from "./invoices";
import { TbFileInvoice } from "react-icons/tb";
import { VscSend } from "react-icons/vsc";
import Messages from "./messages";

let loadCustomersTimeout;

export const DemandLettersDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar notificação extrajudicial" : "Nova notificação extrajudicial");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/demand-letters/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();
  const [consumerUnits, isLoadingConsumerUnits, refreshConsumerUnits] = useFetchData(
    useMemo(
      () => ({
        path: "/private/consumer-units",
        params: { query: { customer: { _id: formData.customer?._id } }, perPage: -1 },
        options: { isEnabled: _.isString(formData.customer?._id) },
      }),
      [formData.customer?._id]
    )
  );
  const [counters, setCounters] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const formData = data ?? { consumerUnits: [], invoices: [] };
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/private/demand-letters/${_id}`, data) : await api.put("/private/demand-letters", data);
        navigate(`/demand-letters/edit/${saved._id}`, { replace: true });
        toast({ comments: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ comments: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const data = {
        ...formData,
        customer: formData.customer?._id,
        consumerUnits: _.map(formData.consumerUnits, "_id"),
        invoices: _.map(formData.invoices, "_id"),
      };
      const shape = {
        customer: yup.string().required(messages.error.required),
        consumerUnits: yup.array().min(1, messages.error.required),
      };
      if (data.type === "overdue_invoices") shape.invoices = yup.array().min(1, messages.error.required);
      const schema = yup.object().shape(shape);
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const response = await api.post("/private/customers", { search, perPage: 20, isAutocomplete: true });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleDownloadData = useCallback(async () => {
    try {
      setIsDownloading(true);
      const response = await api({
        method: "post",
        url: `/private/demand-letters/${_id}/download`,
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" },
      });
      const file = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(file);
      window.open(url);
    } catch (arrayBufferError) {
      const error = JSON.parse(new TextDecoder().decode(arrayBufferError));
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsDownloading(false);
    }
  }, [_id]);

  return (
    <DemandLettersDetailsContext.Provider value={{ formData, setFormData, setCounters, setIsLoading }}>
      <Content>
        <HStack>
          <HStack flex="1">
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/demand-letters")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "pagadoria" },
                { to: "/demand-letters", label: "notificações extrajudiciais" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <Button size="sm" variant="outline" leftIcon={<Icon as={MdDownload} />} isLoading={isDownloading} onClick={handleDownloadData}>
            visualizar documento
          </Button>
          <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              {_id ? (
                <Heading size="md">Notificação extrajudicial #{formData.nid}</Heading>
              ) : (
                <Heading size="md">Nova notificação extrajudicial</Heading>
              )}
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
          </Box>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={{ base: 12, lg: 1 }}>
            <BoxData label="NID" value={formData.nid || "-"} />
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.type}>
              <FormLabel fontSize="sm">Tipo</FormLabel>
              <Select onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))} value={formData.type}>
                <option>--Selecione</option>
                <option value="overdue_invoices">Faturas vencidas</option>
                <option value="consumer_unit_exclusion">Exclusão por inadimplência</option>
              </Select>
              <FormErrorMessage>{formErrors.type}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 8 }}>
            <FormControl isRequired={true} isInvalid={formErrors.customer}>
              <FormLabel fontSize="sm">Cliente</FormLabel>
              <AsyncSelect
                value={formData.customer}
                defaultOptions
                loadOptions={handleLoadCustomers}
                placeholder="Selecione o cliente"
                onChange={(customer) => setFormData((state) => ({ ...state, customer, consumerUnits: [], invoices: [] }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ name, document }) => (
                  <Box>
                    <Text fontSize="sm" fontWeight="semibold">
                      {name}
                    </Text>
                    <Text fontSize="xs">{masks.document(document)}</Text>
                  </Box>
                )}
                isClearable={true}
              />
              <FormErrorMessage>{formErrors.customer}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 12 }}>
            <FormControl isRequired={true} isInvalid={formErrors.consumerUnits}>
              <FormLabel fontSize="sm">Unidades consumidoras</FormLabel>
              <HStack>
                <SyncSelect
                  value={formData.consumerUnits}
                  options={consumerUnits?.data}
                  placeholder="Selecione a unidade consumidora"
                  onChange={(consumerUnits) => setFormData((state) => ({ ...state, consumerUnits }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ cemigInstallationNumber, address }) => (
                    <Box>
                      <Text fontSize="sm" fontWeight="semibold">
                        {cemigInstallationNumber}
                      </Text>
                      <Text fontSize="xs" fontWeight="normal">
                        {address?.line1} - {address?.line2}
                      </Text>
                    </Box>
                  )}
                  isClearable={true}
                  isMulti
                />
                <IconButton
                  variant="outline"
                  icon={<Icon as={MdRefresh} />}
                  isLoading={isLoadingConsumerUnits}
                  onClick={refreshConsumerUnits}
                />
              </HStack>
              <FormErrorMessage>{formErrors.consumerUnits}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 12 }}>
            <FormControl isInvalid={formErrors.comments}>
              <FormLabel fontSize="sm">Observações</FormLabel>
              <Textarea
                value={formData.comments || ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
              />
              <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Divider my={8} />

        <Tabs colorScheme="main" isFitted variant="solid-rounded">
          <TabList>
            {formData.type === "overdue_invoices" && (
              <CustomTab icon={<Icon as={TbFileInvoice} />} title="Faturas" count={_.size(formData.invoices)} />
            )}
            <CustomTab icon={<Icon as={VscSend} />} title="mensagens" isLoading={isLoading.messages} count={counters.messages} />
          </TabList>
          <TabPanels>
            {formData.type === "overdue_invoices" && (
              <TabPanel px="0" py="20px">
                <Invoices />
              </TabPanel>
            )}
            <TabPanel px="0" py="20px">
              <Messages />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Content>

      <Divider />
      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <PermissionedContainer required={"installmentPlans.".concat(_id ? "update" : "create")}>
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
          </PermissionedContainer>
          <Button size="sm" variant="ghost" onClick={() => navigate("/demand-letters")}>
            voltar
          </Button>
        </HStack>
      </SlideFade>

      {_id && (
        <DocumentHistory path={`/private/demand-letters/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
      )}
    </DemandLettersDetailsContext.Provider>
  );
};
