import React, { useState, useCallback, Fragment } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useCustomToast } from "hooks";
import { api, EventEmitter } from "lib";
import { messages } from "consts";
import { TbSend2 } from "react-icons/tb";

const Send = () => {
  const { _id } = useParams();
  const [isSending, setIsSending] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useCustomToast();

  const handleSend = useCallback(async () => {
    try {
      setIsSending(true);
      await api.post(`/private/demand-letters/${_id}/send`);
      EventEmitter.emit("messages.refresh");
      onClose();
      toast({ description: messages.success.messagesSendStarted, status: "info", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsSending(false);
    }
  }, [_id]);

  return (
    <Fragment>
      <Tooltip label="Antes de enviar a mensagem você deve salvar o documento." isDisabled={!!_id} placement="left">
        <Button size="sm" colorScheme="main" leftIcon={<Icon as={TbSend2} />} onClick={onOpen} isDisabled={!_id}>
          enviar notificação
        </Button>
      </Tooltip>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Enviar notificação extrajudicial</AlertDialogHeader>
          <AlertDialogBody>
            <Text>Deseja realmente enviar a notificação extrajudicial?</Text>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" onClick={handleSend} isLoading={isSending}>
              enviar notificação
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};

export default Send;
