import moment from "moment";
import _ from "lodash";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { currency, percent, translator } from "lib";
import { StatusBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="commercialProposals" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "customerName",
    title: "Nome do cliente",
    isVisible: true,
    render: ({ _id, customerName }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {customerName}
      </Link>
    ),
    exporter: ({ customerName }) => customerName,
  },
  {
    accessor: "agreedDiscount",
    title: "Desconto",
    isVisible: true,
    formatter: ({ agreedDiscount }) => percent(agreedDiscount, { precision: 0 }),
  },
  {
    accessor: "powerPricePerKwh",
    title: "Tarif. aplicada",
    isVisible: true,
    formatter: ({ powerPricePerKwh }) => currency(powerPricePerKwh, { precision: 8 }),
  },
  {
    accessor: "monthlyEconomyAmount",
    title: "Economia mensal",
    isVisible: true,
    formatter: ({ monthlyEconomyAmount }) => currency(monthlyEconomyAmount),
  },
  {
    accessor: "yearlyEconomyAmount",
    title: "Economia anual",
    isVisible: true,
    formatter: ({ yearlyEconomyAmount }) => currency(yearlyEconomyAmount),
  },
  {
    accessor: "installationNumbers",
    title: "Un. consumidoras",
    isVisible: true,
    formatter: ({ consumerUnits }) => _.map(consumerUnits, "installationNumber").join(", "),
  },
  {
    accessor: "consultant",
    title: "Consultor",
    isVisible: true,
    formatter: ({ consultant }) => consultant?.name ?? "-",
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
