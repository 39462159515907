import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { AsyncSelect, InputCurrency, PermissionedContainer, SyncSelect } from "components";
import { useArrayItemHandlers, useCustomToast, useFetchData } from "hooks";
import { api, currency, EventEmitter, masks, yup } from "lib";
import { messages } from "consts";
import ReactInputMask from "react-input-mask";
import { MdAddCircleOutline, MdMoneyOffCsred, MdOutlineDelete, MdRefresh } from "react-icons/md";

let loadCustomersTimeout;

export const ChargesCreate = () => {
  const [formData, setFormData] = useState({ items: [], isSingle: true });
  const [formErrors, setFormErrors] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const [consumerUnits, isLoadingConsumerUnits, refreshConsumerUnits] = useFetchData(
    useMemo(
      () => ({
        path: "/private/consumer-units",
        params: { query: { customer: { _id: formData.customer?._id } }, perPage: -1 },
        options: { isEnabled: _.isString(formData.customer?._id) },
      }),
      [formData.customer?._id]
    )
  );
  const toast = useCustomToast();

  useEffect(() => {
    setFormData({ items: [], isSingle: true });
    setFormErrors({});
  }, [isOpen]);

  useEffect(() => {
    setFormData((state) => ({ ...state, amount: _(state.items).map("amount").sum() }));
  }, [formData.items]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.put("/private/charges", data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        EventEmitter.emit("charges.refresh");
        onClose();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [toast]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        customer: yup.string().typeError(messages.error.required).required(messages.error.required),
        dueDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        items: yup
          .array()
          .of(
            yup.object().shape({
              description: yup.string().required(messages.error.required),
              amount: yup.number().moreThan(0, `${messages.error.moreThan} R$0,00.`).required(messages.error.required),
            })
          )
          .min(1, messages.error.required),
        amount: yup.number().moreThan(0, `${messages.error.moreThan} R$0,00.`).required(messages.error.required),
      });
      const data = {
        ...formData,
        customer: formData.customer?._id || null,
        consumerUnit: formData.consumerUnit?._id || null,
        dueDate: moment(formData.dueDate, "DD/MM/YYYY").toDate(),
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const response = await api.post("/private/customers", { search, query: { isActive: true }, perPage: 20, isAutocomplete: true });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <Fragment>
      <PermissionedContainer required="charges.singleCreate">
        <Button size="sm" colorScheme="main" onClick={onOpen}>
          incluir cobrança avulsa
        </Button>
      </PermissionedContainer>
      <Drawer isOpen={isOpen} size="lg" placement="right" blockScrollOnMount={false} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader as={HStack}>
            <Text flex="1">Nova cobrança</Text>
          </DrawerHeader>
          <DrawerBody>
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
              <GridItem colSpan={12}>
                <FormControl isRequired={true} isInvalid={formErrors.customer}>
                  <FormLabel fontSize="sm">Cliente</FormLabel>
                  <AsyncSelect
                    value={formData.customer}
                    defaultOptions
                    loadOptions={handleLoadCustomers}
                    placeholder="Selecione o cliente"
                    onChange={(customer) => setFormData((state) => ({ ...state, customer, consumerUnit: null }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ name, document }) => (
                      <Box>
                        <Text fontSize="sm" fontWeight="semibold">
                          {name}
                        </Text>
                        <Text fontSize="xs">{masks.document(document)}</Text>
                      </Box>
                    )}
                    isClearable={true}
                  />
                  <FormErrorMessage>{formErrors.customer}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={12}>
                <FormControl isInvalid={formErrors.consumerUnit}>
                  <FormLabel fontSize="sm">Unidade consumidora</FormLabel>
                  <HStack>
                    <SyncSelect
                      value={formData.consumerUnit}
                      options={consumerUnits?.data}
                      placeholder="Selecione a unidade consumidora"
                      onChange={(consumerUnit) => setFormData((state) => ({ ...state, consumerUnit }))}
                      getOptionValue={({ _id }) => _id}
                      formatOptionLabel={({ cemigInstallationNumber, address }) => (
                        <Box>
                          <Text fontSize="sm" fontWeight="semibold">
                            {cemigInstallationNumber}
                          </Text>
                          <Text fontSize="xs">
                            {address.line1} - {address.line2}
                          </Text>
                        </Box>
                      )}
                      isClearable={true}
                    />
                    <IconButton
                      variant="outline"
                      icon={<Icon as={MdRefresh} />}
                      isLoading={isLoadingConsumerUnits}
                      onClick={refreshConsumerUnits}
                    />
                  </HStack>
                  <FormErrorMessage>{formErrors.consumerUnit}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={12}>
                <FormControl isInvalid={formErrors.dueDate}>
                  <FormLabel fontSize="sm">Vencimento</FormLabel>
                  <Input
                    as={ReactInputMask}
                    mask="99/99/9999"
                    value={formData.dueDate}
                    onChange={({ target }) => setFormData((state) => ({ ...state, dueDate: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.dueDate}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>

            <Divider my={6} />

            <HStack mb={6}>
              <Box flex="1">
                <Heading size="sm">Itens de cobrança</Heading>
                {_.isString(formErrors.items) && (
                  <Text fontSize="sm" color="red.500">
                    {formErrors.items}
                  </Text>
                )}
              </Box>
              <Button
                size="sm"
                colorScheme="main"
                variant="ghost"
                leftIcon={<Icon as={MdAddCircleOutline} />}
                onClick={() => handleAddArrayItem("items", { description: "", amount: 0 })}
              >
                adicionar item de cobrança
              </Button>
            </HStack>

            <VStack align="stretch" divider={<StackDivider opacity={{ base: "1", lg: "0" }} />} spacing={{ base: "30px", lg: "10px" }}>
              {_.map(formData.items, (item, index) => (
                <Grid key={index} templateColumns="repeat(12, 1fr)" gap={4}>
                  <GridItem colSpan={{ base: 12, lg: 8 }}>
                    <FormControl isRequired={true} isInvalid={formErrors.items?.[index].description}>
                      <FormLabel fontSize="sm">Descrição</FormLabel>
                      <Input
                        value={item.description ?? ""}
                        onChange={({ target }) => handleChangeArrayItem("items", index, { description: target.value })}
                      />
                      <FormErrorMessage>{formErrors.items?.[index].description}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 12, lg: 4 }}>
                    <FormControl isRequired={true} isInvalid={formErrors.items?.[index].amount}>
                      <FormLabel fontSize="sm">Valor</FormLabel>
                      <HStack>
                        <InputGroup flex="1">
                          <InputLeftAddon fontSize="xs">R$</InputLeftAddon>
                          <Input
                            as={InputCurrency}
                            value={item.amount ?? ""}
                            onChange={(amount) => handleChangeArrayItem("items", index, { amount })}
                          />
                        </InputGroup>
                        <IconButton
                          size="md"
                          variant="outline"
                          icon={<Icon as={MdOutlineDelete} />}
                          onClick={() => handleDeleteArrayItem("items", index)}
                        />
                      </HStack>
                      <FormErrorMessage>{formErrors.items?.[index].amount}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                </Grid>
              ))}
            </VStack>

            {_.size(formData.items) === 0 && (
              <Center paddingTop="40px" paddingBottom="20px">
                <Box textAlign="center">
                  <Icon as={MdMoneyOffCsred} boxSize={20} marginBottom="10px" />
                  <Text fontSize="lg" fontWeight="bold">
                    Nenhum item de cobrança adicionado
                  </Text>
                  <Text fontSize="sm">Este cobrança ainda não possui itens adicionados.</Text>
                </Box>
              </Center>
            )}

            <Divider my={6} />

            <HStack p="20px" borderRadius="lg" _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }}>
              <Text flex="1">Valor total</Text>
              <Heading size="sm">{currency(formData.amount)}</Heading>
            </HStack>
          </DrawerBody>
          <Divider />
          <DrawerFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Fragment>
  );
};
