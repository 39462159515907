import React, { useState, useEffect, useMemo, useCallback, Fragment } from "react";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ObjectID from "bson-objectid";
import _ from "lodash";
import moment from "moment";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  SlideFade,
  Spinner,
  StackDivider,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { BankSlipDownload, BankSlipSend, ChargeCancel, ChargeUpdate } from "containers";
import { Breadcrumb, CustomTab, ErrViewer, PermissionedContainer, Portal, StatusBadge, SyncSelect } from "components";
import { DocumentHistory } from "containers";
import { useFetchData, useClipboard, useCustomToast, useDocumentTitle, usePermissioned, useNewTabNavigate } from "hooks";
import { EventEmitter, api, yup } from "lib";
import { messages } from "consts";
import { TbExternalLink, TbFileInvoice, TbPigMoney } from "react-icons/tb";
import { MdChevronLeft, MdHistory, MdRefresh, MdCheck, MdAttachMoney, MdMoreHoriz, MdOutlineQrCodeScanner, MdSave } from "react-icons/md";
import { RiAuctionFill, RiRefreshLine } from "react-icons/ri";
import { Content } from "pages/Private/Container";
import InvoicesDetailsContext from "./context";
import General from "./general";
import Charges from "./charges";
import Payments from "./payments";
import { BiBarcodeReader, BiListUl, BiSupport } from "react-icons/bi";
import { LuHardDriveDownload } from "react-icons/lu";
import CreateCharge from "./createCharge";
import MessagesList from "./messages";
import { VscSend } from "react-icons/vsc";
import { HiOutlineBolt } from "react-icons/hi2";
import SentToCollectionAgency from "./sentToCollectionAgency";
import { AiOutlineAlert } from "react-icons/ai";
import ProtestOrSpcRegister from "./protestOrSpcRegister";
import Analyze from "./analyze";
import InvalidatedReasons from "./invalidatedReasons";
import AddDigitableLine from "./addDigitableLine";

export const getDefaultTraderEletricPower = () => ({
  _id: ObjectID().toHexString(),
  type: "eletric_power",
  description: "Energia injetada pela ClickLivre",
  basePowerPricePerKwh: 0,
  nonCompensablePowerPricePerKwh: 0,
  powerPricePerKwh: 0,
  basePowerQttInKwh: 0,
  powerQttAdjustments: [],
  powerQttInKwh: 0,
  amount: 0,
});

export const InvoicesDetails = () => {
  const { _id } = useParams();
  useDocumentTitle("Detalhamento da fatura");
  const navigate = useNavigate();
  const location = useLocation();
  const isAllowed = {
    invoicesSave: usePermissioned("invoices.".concat(_id ? "update" : "create")),
    chargesRead: usePermissioned(["charges.read", "charges.readOnlySentToCollectionAgency"]),
    paymentsRead: usePermissioned("payments.read"),
  };
  const [data, isLoadingData, refreshData] = useFetchData(
    useMemo(
      () => ({
        path: `/private/invoices/${_id}`,
        options: { isEnabled: _.isString(_id) },
      }),
      [_id]
    )
  );
  const [invoiceTypes, isLoadingInvoiceTypes, refreshInvoiceTypes] = useFetchData(
    useMemo(
      () => ({
        path: "/private/invoice-types",
        params: { perPage: -1, isAutocomplete: true },
      }),
      []
    )
  );
  const [counters, setCounters] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const { isOpen: isOpenReprocessDialog, onOpen: onOpenReprocessDialog, onClose: onCloseReprocessDialog } = useDisclosure();
  const { isOpen: isOpenAnalyzeDialog, onOpen: onOpenAnalyzeDialog, onClose: onCloseAnalyzeDialog } = useDisclosure();
  const { isOpen: isOpenCreateCharge, onOpen: onOpenCreateCharge, onClose: onCloseCreateCharge } = useDisclosure();
  const isAnalyzing = useMemo(() => formData.status === "analyzing", [formData.status]);
  const isEditable = useMemo(
    () => formData.type === "manual" && isAnalyzing === true && isAllowed.invoicesSave,
    [formData.type, isAnalyzing, isAllowed.invoicesSave]
  );
  const isAllowedChargesCreate = useMemo(() => _.includes(["validated", "issue_failed"], formData.status), [formData.status]);
  const isChargedOrExempt = useMemo(
    () => _.includes(["exempt", "created", "updated", "paid", "finished", "update_failed"], formData.status),
    [formData.status]
  );
  const isChargePending = useMemo(() => _.includes(["created", "updated", "update_failed"], formData.status), [formData.status]);
  const isChargeActive = useMemo(() => _.includes(["created", "updated", "paid"], formData.status), [formData.status]);
  const toast = useCustomToast();
  const copyToClipboard = useClipboard();
  const newTabNavigate = useNewTabNavigate();

  useEffect(() => {
    const formData = (() => {
      if (_id) return data || {};
      return {
        type: "manual",
        status: "analyzing",
        agreedDiscount: {
          percentage: 0,
          economyAmount: 0,
          amount: 0,
        },
        referenceDate: moment().startOf("day").toDate(),
        dueDate: moment().endOf("day").toDate(),
        discountPercentage: 0,
        economyAmount: 0,
        amount: 0,
        receivableAmount: 0,
        isUnified: false,
        items: [getDefaultTraderEletricPower()],
      };
    })();
    if (formData.dueDate) formData.dueDate = moment(formData.dueDate).format("DD/MM/YYYY");
    if (formData.referenceDate) formData.referenceDate = moment(formData.referenceDate).format("MMM/YYYY").toUpperCase();
    setFormData(formData);
  }, [_id, data]);

  const handleBaseSaveData = useCallback(
    async (func, cb) => {
      try {
        setIsLoadingSaveData(true);
        const saved = await func();
        if (saved?._id) navigate(`/invoices/details/${saved._id}`, { replace: true });
        refreshData();
        if (cb instanceof Function) cb();
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [refreshData, toast]
  );

  const handleSaveData = useCallback(
    (data, cb) => handleBaseSaveData(() => (_id ? api.patch("/private/invoices", [data]) : api.put("/private/invoices", data)), cb),
    [_id, handleBaseSaveData]
  );

  const handleActionData = useCallback(
    (actionType, cb) => handleBaseSaveData(() => api.patch(`/private/invoices/${_id}/actions/${actionType}`), cb),
    [_id, handleBaseSaveData]
  );

  const handleSaveInvoiceTypeData = useCallback(
    () => handleBaseSaveData(() => api.patch("/private/invoices", [{ _id, invoiceType: formData.invoiceType?._id }])),
    [_id, formData.invoiceType?._id, handleBaseSaveData]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        referenceDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        dueDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
      });
      const data = {
        ...formData,
        invoiceType: formData.invoiceType?._id || null,
        referenceDate: moment(formData.referenceDate, "MMM/YYYY").toDate(),
        dueDate: moment(formData.dueDate, "DD/MM/YYYY").toDate(),
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleRefreshData = useCallback(() => {
    EventEmitter.emit("charges.refresh");
    EventEmitter.emit("payments.refresh");
    EventEmitter.emit("messages.refresh");
    refreshData();
  }, [refreshData]);

  return (
    <InvoicesDetailsContext.Provider
      value={{
        formData,
        setFormData,
        refreshData,
        isLoadingSaveData,
        handleSaveData,
        isAnalyzing,
        handleActionData,
        setCounters,
        setIsLoading,
        formErrors,
        isEditable,
        handleRefreshData,
      }}
    >
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "financeiro" },
                { to: "/invoices", label: "faturas capturadas" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <HStack>
            {formData.consumerUnit?._id && (
              <PermissionedContainer required="powerManagement.read">
                <Button
                  size="sm"
                  variant="outline"
                  leftIcon={<Icon as={HiOutlineBolt} />}
                  rightIcon={<Icon as={TbExternalLink} />}
                  onClick={() => newTabNavigate("/power-management", { installationNumber: formData.consumerUnit.cemigInstallationNumber })}
                >
                  gestão de energia
                </Button>
              </PermissionedContainer>
            )}
            {formData.customer?._id && (
              <PermissionedContainer required="invoices.read">
                <Button
                  size="sm"
                  variant="outline"
                  leftIcon={<Icon as={TbFileInvoice} />}
                  rightIcon={<Icon as={TbExternalLink} />}
                  onClick={() => newTabNavigate("/invoices", { customer: [{ _id: formData.customer._id, name: formData.customer.name }] })}
                >
                  histórico de faturas
                </Button>
              </PermissionedContainer>
            )}
            <PermissionedContainer required={["charges.create", "invoices.update"]}>
              <Box>
                <Menu placement="bottom">
                  <MenuButton
                    as={Button}
                    size="sm"
                    variant="outline"
                    isLoading={isLoadingData || isLoadingSaveData}
                    rightIcon={<Icon as={MdMoreHoriz} />}
                  >
                    mais ações
                  </MenuButton>
                  <Portal>
                    <MenuList fontSize="sm">
                      <VStack spacing={2} divider={<StackDivider />}>
                        {_.isString(_id) && formData.type === "automatic" && _.size(formData.distributor?.current?.digitableLine) === 0 && (
                          <AddDigitableLine />
                        )}

                        {isAllowedChargesCreate && (
                          <PermissionedContainer required="charges.create">
                            <MenuItem onClick={onOpenCreateCharge}>
                              <HStack>
                                <Icon as={TbPigMoney} />
                                <Text>emitir cobrança</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                        )}
                        <Fragment>
                          <BankSlipDownload invoice={formData} isPreview={isChargedOrExempt === false} />
                          {isChargedOrExempt && (
                            <BankSlipSend appearance="MenuItem" invoice={formData} charge={formData.charge} customer={formData.customer} />
                          )}
                        </Fragment>
                        {isChargeActive && (
                          <Fragment>
                            <MenuItem onClick={() => copyToClipboard(formData.charge.digitableLine)}>
                              <HStack>
                                <Icon as={BiBarcodeReader} />
                                <Text>copiar linha digitável</Text>
                              </HStack>
                            </MenuItem>
                            <MenuItem onClick={() => copyToClipboard(formData.charge.pixInfo.emv)}>
                              <HStack>
                                <Icon as={MdOutlineQrCodeScanner} />
                                <Text>copiar QrCode PIX</Text>
                              </HStack>
                            </MenuItem>
                          </Fragment>
                        )}
                        {isChargePending && <ChargeUpdate charge={{ ...formData.charge, invoice: formData }} />}
                        {isChargePending && <ChargeCancel charge={{ ...formData.charge, invoice: formData }} />}
                        {!formData.charge && (
                          <PermissionedContainer required="invoices.update">
                            <MenuItem onClick={onOpenReprocessDialog}>
                              <HStack>
                                <Icon as={RiRefreshLine} />
                                <Text>reprocessar fatura</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                        )}
                        {isAnalyzing && (
                          <PermissionedContainer required={["invoices.validate", "invoices.invalidate"]}>
                            <MenuItem onClick={onOpenAnalyzeDialog}>
                              <HStack>
                                <Icon as={MdCheck} />
                                <Text>enviar análise</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                        )}
                      </VStack>
                    </MenuList>
                  </Portal>
                </Menu>
              </Box>
            </PermissionedContainer>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={handleRefreshData} />
            {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
          </HStack>
        </HStack>

        {formData.sentToCollectionAgency && (
          <HStack bg="purple.100" p="15px" borderRadius="lg" mt={4}>
            <Center bg="purple.500" w="40px" h="40px" borderRadius="full" color="white">
              <Icon as={BiSupport} boxSize="25px" />
            </Center>
            <Box flex="1">
              <Heading size="sm">Fatura em cobrança terceirizada</Heading>
              <Text fontSize="xs">
                Esta fatura foi enviada para cobrança terceirizada em{" "}
                {moment(formData.sentToCollectionAgencyAt).format("DD/MM/YYYY [às] HH:mm:ss")} por{" "}
                {formData.sentToCollectionAgencyBy?.name || "-"}
              </Text>
            </Box>
          </HStack>
        )}

        {(formData.isProtested || formData.isSpcClean || formData.isSpcDirty) && (
          <HStack bg="purple.100" p="15px" borderRadius="lg" mt={4}>
            <Center bg="purple.500" w="40px" h="40px" borderRadius="full" color="white">
              <Icon as={RiAuctionFill} boxSize="25px" />
            </Center>
            <Box flex="1">
              <Heading size="sm">Fatura com protesto</Heading>
              <Text fontSize="xs">Esta fatura possui protesto ou está no SPC.</Text>
            </Box>
          </HStack>
        )}

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Fatura #{formData.nid}</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id}</Text>
          </Box>
        </HStack>

        {formData.alertsCount >= 1 && (
          <HStack p="15px" bg="yellow.100" borderRadius="md" mb={6}>
            <Center w="30px" h="30px" borderRadius="full" bg="yellow.500">
              <Icon as={AiOutlineAlert} color="white" />
            </Center>
            <Box flex="1">
              <Text fontSize="sm" fontWeight="semibold">
                {formData.alertsCount} {formData.alertsCount === 1 ? "alerta pendente" : "alertas pendentes"}
              </Text>
              <Text fontSize="sm">
                Esta fatura possui {formData.alertsCount} {formData.alertsCount === 1 ? "alerta pendente" : "alertas pendentes"}. Por favor,
                verifique a informação.
              </Text>
            </Box>
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={TbExternalLink} />}
              onClick={() => newTabNavigate("/alerts", { referenceCode: formData.referenceCode })}
            />
          </HStack>
        )}

        {formData.distributor?.waiting && (
          <HStack
            _light={{ bg: formData.charge ? "red.100" : "yellow.100" }}
            _dark={{ bg: "whiteAlpha.100" }}
            p="15px"
            borderRadius="md"
            mb={6}
          >
            <Center w="30px" h="30px" borderRadius="full" bg={formData.charge ? "red.500" : "yellow.500"}>
              <Icon as={LuHardDriveDownload} color="white" />
            </Center>
            <Box>
              <Text fontSize="sm" fontWeight="semibold">
                Fatura em aviso
              </Text>
              <Text fontSize="sm">
                {formData.charge
                  ? "Esta fatura está pendente de revisão por ter sido alterada após a emissão de cobrança."
                  : "Esta fatura está pendente de revisão por ter sido alterada após a análise do operador."}
              </Text>
            </Box>
          </HStack>
        )}

        <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={4}>
          <GridItem colSpan={4}>
            <Text fontSize="xs">Status</Text>
            <HStack>
              <StatusBadge schema="invoices" status={formData.status} />
              {formData.status === "invalidated" && <InvalidatedReasons />}
            </HStack>
          </GridItem>
          <GridItem colSpan={4}>
            <Text fontSize="xs">Código de referência</Text>
            <Text fontSize="sm" fontWeight="semibold">
              {formData.referenceCode}
            </Text>
          </GridItem>
          <GridItem colSpan={4}>
            <HStack>
              <Box>
                <Text fontSize="xs">Captura de faturas</Text>
                <Box w="100px">
                  <Text fontSize="sm" fontWeight="semibold" noOfLines={1}>
                    {formData.invoiceCapture || "-"}
                  </Text>
                </Box>
              </Box>
              <IconButton
                size="xs"
                variant="outline"
                as={RouterLink}
                icon={<Icon as={TbExternalLink} />}
                to={`/invoice-captures/details/${formData.invoiceCapture}`}
                target="_blank"
              />
            </HStack>
          </GridItem>
          <GridItem colSpan={3}>
            <Text fontSize="xs">Criada em</Text>
            <Text fontSize="sm" fontWeight="semibold" lineHeight="xs">
              {moment(formData.createdAt).format("DD/MM/YYYY [às] HH:mm")}
            </Text>
            <Text fontSize="xs" lineHeight="xs">
              por {formData.createdBy?.name || "-"}
            </Text>
          </GridItem>

          <GridItem colSpan={3}>
            <Text fontSize="xs">Analisada em</Text>
            {formData.analyzedAt ? (
              <>
                <Text fontSize="sm" fontWeight="semibold" lineHeight="xs">
                  {moment(formData.analyzedAt).format("DD/MM/YYYY [às] HH:mm")}
                </Text>
                <Text fontSize="xs" lineHeight="xs">
                  por {formData.analyzedBy?.name || "-"}
                </Text>
              </>
            ) : (
              "-"
            )}
          </GridItem>

          <GridItem colSpan={3}>
            <Text fontSize="xs">Finalizada em</Text>
            <Text fontSize="sm" fontWeight="semibold" lineHeight="xs">
              {formData.finishedAt ? moment(formData.finishedAt).format("DD/MM/YYYY [às] HH:mm") : "-"}
            </Text>
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl>
              <FormLabel fontSize="xs" mb="4px">
                Tipo de fatura
              </FormLabel>
              <HStack>
                <SyncSelect
                  size="sm"
                  placeholder="Selecione"
                  options={invoiceTypes?.data || []}
                  value={formData.invoiceType || {}}
                  onChange={(invoiceType) => setFormData((state) => ({ ...state, invoiceType }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
                <IconButton
                  size="sm"
                  variant="outline"
                  icon={<Icon as={MdRefresh} />}
                  isLoading={isLoadingInvoiceTypes}
                  onClick={refreshInvoiceTypes}
                />
                <IconButton
                  size="sm"
                  colorScheme="main"
                  icon={<Icon as={MdSave} />}
                  isLoading={isLoadingSaveData}
                  onClick={handleSaveInvoiceTypeData}
                  isDisabled={!formData.invoiceType?._id}
                />
              </HStack>
            </FormControl>
          </GridItem>
        </Grid>

        {_.isObject(formData.err) && <ErrViewer err={formData.err} />}

        <Divider my={6} />

        <Tabs colorScheme="main" isFitted variant="solid-rounded">
          <TabList>
            <CustomTab icon={<Icon as={BiListUl} />} title="dados gerais" />
            {isAllowed.chargesRead && (
              <CustomTab icon={<Icon as={TbPigMoney} />} title="cobranças" isLoading={isLoading.charges} count={counters.charges} />
            )}
            {isAllowed.paymentsRead && (
              <CustomTab icon={<Icon as={MdAttachMoney} />} title="pagamentos" isLoading={isLoading.payments} count={counters.payments} />
            )}
            <CustomTab icon={<Icon as={VscSend} />} title="mensagens" isLoading={isLoading.messages} count={counters.messages} />
          </TabList>

          <TabPanels>
            <TabPanel px="0" py="20px">
              <General />
              <HStack justifyContent="flex-end">
                <SentToCollectionAgency />
                <ProtestOrSpcRegister field="isProtested" includeText="Protesto" excludeText="Remover protesto" protocolField="protested" />
                <ProtestOrSpcRegister field="isSpcClean" includeText="SPC Limpo" excludeText="Remover SPC Limpo" protocolField="spcClean" />
                <ProtestOrSpcRegister field="isSpcDirty" includeText="SPC Sujo" excludeText="Remover SPC Sujo" protocolField="spcDirty" />
              </HStack>
            </TabPanel>
            {isAllowed.chargesRead && (
              <TabPanel px="0" py="20px">
                <Charges />
              </TabPanel>
            )}
            {isAllowed.paymentsRead && (
              <TabPanel px="0" py="20px">
                <Payments />
              </TabPanel>
            )}
            <TabPanel px="0" py="20px">
              <MessagesList />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Content>

      {isAnalyzing && (
        <PermissionedContainer required={"invoices.".concat(_id ? "update" : "create")}>
          <Divider />
          <SlideFade in={true} offsetY="20px">
            <HStack p="20px">
              <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
                salvar
              </Button>
              <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
                cancelar
              </Button>
            </HStack>
          </SlideFade>
        </PermissionedContainer>
      )}

      <AlertDialog isOpen={isOpenReprocessDialog} onClose={onCloseReprocessDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente reprocessar esta fatura?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onCloseReprocessDialog}>
              cancelar
            </Button>
            <Button
              size="sm"
              colorScheme="yellow"
              isLoading={isLoadingSaveData}
              onClick={handleActionData.bind(this, "reprocess", onCloseReprocessDialog)}
            >
              reprocessar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <CreateCharge isOpen={isOpenCreateCharge} onClose={onCloseCreateCharge} />
      <Analyze isOpen={isOpenAnalyzeDialog} onClose={onCloseAnalyzeDialog} />
      {_id && <DocumentHistory path={`/private/invoices/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
    </InvoicesDetailsContext.Provider>
  );
};
