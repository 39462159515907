import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { MdAdd, MdClose, MdRefresh } from "react-icons/md";
import { CheckboxBody, CheckboxHeader, CheckboxProvider, StatusBadge, TableEmpty } from "components";
import { useFetchData } from "hooks";
import { currency } from "lib";
import { TbExternalLink } from "react-icons/tb";
import DemandLettersDetailsContext from "./context";

export const InvoicesSelect = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { formData, setFormData } = useContext(DemandLettersDetailsContext);
  const [checkeds, setCheckeds] = useState([]);
  const [invoices, isLoadingInvoices, refreshInvoices] = useFetchData(
    useMemo(() => {
      const query = {
        customer: { _id: formData.customer?._id },
        consumerUnit: { _id: { $in: _.map(formData.consumerUnits, "_id") } },
        invoice: { status: { $in: ["validated", "created", "updated"] }, overdueDays: { $gt: 0 } },
      };
      return {
        path: "/private/invoices",
        params: { query },
        options: { isEnabled: isOpen && _.isObject(formData.customer) },
      };
    }, [isOpen, formData.customer, formData.consumerUnits])
  );

  const handleSubmit = useCallback(() => {
    setFormData((state) => ({ ...state, invoices: _.map(checkeds, (o) => ({ ...o })) }));
    setCheckeds([]);
    onClose();
  }, [checkeds]);

  return (
    <Fragment>
      <Tooltip
        label="Antes de adicionar as faturas você deve selecionar o cliente."
        placement="left"
        isDisabled={_.isObject(formData.customer)}
      >
        <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdAdd} />} onClick={onOpen} isDisabled={!_.isObject(formData.customer)}>
          selecionar faturas
        </Button>
      </Tooltip>
      <Modal
        size="6xl"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        scrollBehavior="inside"
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack}>
            <Box flex="1">
              <Text fontSize="md">Faturas</Text>
              <Text fontSize="sm" fontWeight="light">
                Selecione as faturas que deverão fazer parte desta notificação.
              </Text>
            </Box>
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={MdRefresh} />}
              isLoading={isLoadingInvoices}
              onClick={refreshInvoices}
            />
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody>
            <CheckboxProvider values={checkeds} onChange={setCheckeds}>
              <Table size="sm" whiteSpace="nowrap">
                <Thead>
                  <Tr>
                    <Th>
                      <CheckboxHeader />
                    </Th>
                    <Th>Nid</Th>
                    <Th>Status</Th>
                    <Th>Tipo</Th>
                    <Th>Cod. referência</Th>
                    <Th>N° instalação</Th>
                    <Th>Dt. vencimento</Th>
                    <Th>Valor Click</Th>
                    <Th>#</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {_.map(invoices?.data, (item) => (
                    <Tr key={item._id}>
                      <Td>
                        <CheckboxBody value={item} />
                      </Td>
                      <Td>{item.nid}</Td>
                      <Td>
                        <StatusBadge schema="invoices" status={item.status} />
                      </Td>
                      <Td>{item.invoiceType?.title || "-"}</Td>
                      <Td>{item.referenceCode}</Td>
                      <Td>{item.consumerUnit?.cemigInstallationNumber || "-"}</Td>
                      <Td>{moment(item.dueDate).format("DD/MM/YYYY")}</Td>
                      <Td>{currency(item.amount)}</Td>
                      <Td>
                        <IconButton
                          size="sm"
                          variant="outline"
                          as={RouterLink}
                          target="_blank"
                          to={`/invoices/details/${item._id}`}
                          icon={<Icon as={TbExternalLink} />}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {isLoadingInvoices && (
                <Center p="30px">
                  <Spinner />
                </Center>
              )}
              <TableEmpty isLoading={isLoadingInvoices} size={_.size(invoices?.data)} />
            </CheckboxProvider>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <HStack>
              <Button size="sm" variant="outline" onClick={onClose}>
                fechar
              </Button>
              <Button size="sm" colorScheme="main" onClick={handleSubmit} isDisabled={checkeds.length === 0}>
                confirmar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};
