import React, { useState, useCallback, useEffect, useMemo, Fragment, useContext } from "react";
import _ from "lodash";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Parser } from "@json2csv/plainjs";
import { useCustomToast } from "hooks";
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox, MdOutlineIndeterminateCheckBox } from "react-icons/md";
import fileDownload from "js-file-download";
import { FiDownloadCloud } from "react-icons/fi";
import { SocketContext } from "SocketProvider";
import { locale, percent } from "lib";

const getExportableArray = (data, visibles, isAllowedExportSensitiveData, isAllowedExportSensitiveCommercialData) => {
  return _.map(data, (item) => {
    const tmp = {};
    for (const { accessor, formatter, exporter } of visibles) {
      _.set(
        tmp,
        accessor,
        formatter?.(item) ?? exporter?.(item, { isAllowedExportSensitiveData, isAllowedExportSensitiveCommercialData }) ?? ""
      );
    }
    return tmp;
  });
};

export const ExportCsvStream = ({
  filename,
  columns,
  isOpen,
  onClose,
  onStreamData,
  isAllowedExportSensitiveData,
  isAllowedExportSensitiveCommercialData,
}) => {
  const { socket } = useContext(SocketContext);
  const [headers, setHeaders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [stream, setStream] = useState("");
  const [exportation, setExportation] = useState({});
  const checkeds = useMemo(() => headers.filter((o) => o.isVisible), [headers]);
  const toast = useCustomToast();

  useEffect(() => {
    setHeaders([...columns]);
  }, [columns]);

  useEffect(() => {
    if (stream && socket) {
      const onSave = (data) => {
        setExportation((state) => {
          if (state.status === "in_progress") return data;
          return state;
        });
      };
      const key = "exportation.save.".concat(stream);
      socket.on(key, onSave);
      return () => socket.removeListener(key);
    }
  }, [stream, socket]);

  const handleChange = useCallback((index) => {
    setHeaders((state) => {
      const tmp = [...state];
      tmp[index].isVisible = !tmp[index].isVisible;
      return tmp;
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      const exportation = await onStreamData(_.map(checkeds, "accessor"));
      setStream(exportation._id);
      setExportation(exportation);
      onClose();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoading(false);
    }
  }, [checkeds, filename, onStreamData, onClose]);

  const handleDownloadFile = useCallback(async () => {
    try {
      setIsDownloading(true);
      const response = await axios.get(exportation.url, { responseType: "json" });
      const exportableArray = getExportableArray(
        response.data,
        checkeds,
        isAllowedExportSensitiveData,
        isAllowedExportSensitiveCommercialData
      );
      const parser = new Parser({
        fields: checkeds.map((o) => ({ value: o.accessor, label: o.title })),
        delimiter: ";",
        withBOM: true,
      });
      const csv = parser.parse(exportableArray);
      fileDownload(csv, `${filename}_${new Date().toISOString()}.csv`);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setStream("");
      setExportation({});
      setIsDownloading(false);
    }
  }, [checkeds, exportation, isAllowedExportSensitiveData, isAllowedExportSensitiveCommercialData]);

  return (
    <Fragment>
      <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Text>Exportar arquivo CSV</Text>
            {headers.length === checkeds.length ? (
              <Button
                size="sm"
                variant="outline"
                leftIcon={<Icon as={MdOutlineCheckBox} />}
                onClick={() => setHeaders(_.map(headers, (o) => ({ ...o, isVisible: false })))}
              >
                desmarcar todos
              </Button>
            ) : (
              <Button
                size="sm"
                variant="outline"
                leftIcon={<Icon as={checkeds.length === 0 ? MdCheckBoxOutlineBlank : MdOutlineIndeterminateCheckBox} />}
                onClick={() => setHeaders(_.map(headers, (o) => ({ ...o, isVisible: true })))}
              >
                marcar todos
              </Button>
            )}
          </ModalHeader>
          <ModalBody>
            <Grid templateColumns="repeat(4, 1fr)" gap={4}>
              {_.map(headers, (item, index) => (
                <GridItem key={item.accessor}>
                  <Checkbox key={item.accessor} colorScheme="main" isChecked={item.isVisible} onChange={() => handleChange(index)}>
                    <Text fontSize="xs">{item.title}</Text>
                  </Checkbox>
                </GridItem>
              ))}
            </Grid>
          </ModalBody>
          <ModalFooter as={HStack}>
            <Button size="sm" variant="outline" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" isDisabled={headers.length === 0} isLoading={isLoading} onClick={handleSubmit}>
              exportar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={_.isString(exportation._id)}
        onClose={() => setExportation({})}
        isCentered={true}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody as={VStack} justifyContent="center" alignItems="center" textAlign="center" p="40px">
            {exportation.status === "in_progress" ? (
              <Fragment>
                <Heading size="md">Exportação em andamento</Heading>
                <Text fontSize="sm">Por favor, aguarde enquanto a exportação é preparada no servidor.</Text>
              </Fragment>
            ) : (
              <Fragment>
                <Heading size="md">Exportação finalizada</Heading>
                <Text fontSize="sm" mb={4}>
                  Os dados para exportação já estão prontos. Clique no botão abaixo para baixar o arquivo.
                </Text>
              </Fragment>
            )}
            {exportation.status === "in_progress" && (
              <Fragment>
                <Box w="100%">
                  <Progress hasStripe value={(exportation.progress || 0) * 100} />
                </Box>
                <Text>{percent(exportation.progress, { precision: 0 })}</Text>
                <Text fontSize="xs" mb={4}>
                  {locale(exportation.processedCount, { precision: 0 })}/{locale(exportation.documentsCount, { precision: 0 })}
                </Text>
              </Fragment>
            )}
            {exportation.status === "finished" && (
              <Button
                size="sm"
                variant="outline"
                rightIcon={<Icon as={FiDownloadCloud} />}
                isLoading={isDownloading}
                onClick={handleDownloadFile}
              >
                baixar arquivo
              </Button>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};
