import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useStickyState, usePermissioned, useCacheState } from "hooks";
import { api, translator } from "lib";
import { AsyncSelect, SyncSelect, RangeDateInput } from "components";
import { statuses } from "consts";
import { MdClose, MdSearch, MdRefresh } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

let loadUsersTimeout;

const Filters = ({ onQuery, onSearch, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: location.state ?? { status: [] },
        useCached: _.isObject(location.state) === false,
        _v: 2,
        processor: (data) => ({
          ...data,
          updatedAtStart: data.updatedAtStart && moment(data.updatedAtStart).toDate(),
          updatedAtEnd: data.updatedAtEnd && moment(data.updatedAtEnd).toDate(),
          createdAtStart: data.createdAtStart && moment(data.createdAtStart).toDate(),
          createdAtEnd: data.createdAtEnd && moment(data.createdAtEnd).toDate(),
        }),
      }),
      [location.pathname, location.state]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const isAllowedCommercialProposalsRead = usePermissioned("commercialProposals.read");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    if (query.status?.length) _.set(response, "status.$in", _.map(query.status, "value"));
    if (query.consultant?.length) _.set(response, "consultant.$in", _.map(query.consultant, "_id"));
    if (query.updatedAtStart) _.set(response, "updatedAt.$gte", query.updatedAtStart);
    if (query.updatedAtEnd) _.set(response, "updatedAt.$lte", query.updatedAtEnd);
    if (query.createdAtStart) _.set(response, "createdAt.$gte", query.createdAtStart);
    if (query.createdAtEnd) _.set(response, "createdAt.$lte", query.createdAtEnd);
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query, location.state]);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const response = await api.post("/private/users", { search, perPage: -1, sort: { name: 1 }, isAutocomplete: true, withSystem: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    const [{ value }] = e.target;
    onSearch(value);
    onPage(0);
  }, []);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <Grid templateColumns="repeat(12,1fr)" gap={2} mb={8}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <form onSubmit={handleSearch}>
            <InputGroup width="100%">
              <Input variant="filled" placeholder="Pesquisar..." />
              <InputRightElement>
                <IconButton type="submit" size="sm" variant="ghost" icon={<Icon as={MdSearch} />} isLoading={isLoading} />
              </InputRightElement>
            </InputGroup>
          </form>
        </GridItem>
        <GridItem as={HStack} justifyContent="space-between" colSpan={{ base: 12, lg: 8 }}>
          <HStack>
            <Button
              colorScheme={isFiltering ? "main" : "gray"}
              variant="outline"
              rightIcon={<Icon as={HiOutlineFilter} />}
              onClick={() => setIsOpen((state) => !state)}
            >
              filtros
            </Button>
            {isFiltering && (
              <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
                limpar filtros
              </Button>
            )}
          </HStack>
          <HStack>
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
            <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
          </HStack>
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            {isAllowedCommercialProposalsRead && (
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <FormControl>
                  <FormLabel fontSize="xs" mb="5px">
                    Consultor
                  </FormLabel>
                  <AsyncSelect
                    size="sm"
                    variant="filled"
                    isMulti
                    value={formData.consultant ?? []}
                    defaultOptions
                    loadOptions={handleLoadUsers}
                    placeholder="Selecione"
                    onChange={(consultant) => setFormData((state) => ({ ...state, consultant }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ name }) => name}
                    isClearable={true}
                  />
                </FormControl>
              </GridItem>
            )}
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.commercialProposals}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Atualizado em
                </FormLabel>
                <RangeDateInput
                  defaultStartDate={formData.updatedAtStart}
                  defaultEndDate={formData.updatedAtEnd}
                  onChange={(updatedAtStart, updatedAtEnd) => setFormData((state) => ({ ...state, updatedAtStart, updatedAtEnd }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado em
                </FormLabel>
                <RangeDateInput
                  defaultStartDate={formData.createdAtStart}
                  defaultEndDate={formData.createdAtEnd}
                  onChange={(createdAtStart, createdAtEnd) => setFormData((state) => ({ ...state, createdAtStart, createdAtEnd }))}
                />
              </FormControl>
            </GridItem>
          </Grid>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
