import React, { useCallback, useContext } from "react";
import _ from "lodash";
import { Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { PermissionedContainer } from "components";
import { MdBlock, MdCheck } from "react-icons/md";
import CustomersDetailsContext from "./context";

const ContactVerify = ({ type, value = "", isVerified = false, isDisabled }) => {
  const { setFormData } = useContext(CustomersDetailsContext);

  const handleVerify = useCallback(() => {
    const switchVerify = (obj, key) => {
      const [prev, next] = (() => {
        if (type === "phone") return [obj[key]?.replace(/\D/g, ""), value.replace(/\D/g, "")];
        return [obj[key], value];
      })();
      if (prev !== next) return;
      obj[key.concat("Verified")] = !isVerified;
    };
    setFormData((state) => {
      const tmp = { ...state };
      for (const key of ["phone1", "phone2", "phone3", "email"]) switchVerify(tmp, key);
      return tmp;
    });
  }, [type, value, isVerified, setFormData]);

  return (
    <PermissionedContainer required="customers.verifyContact">
      <Tooltip label={isVerified ? "Verificado" : "Não verificado"}>
        <IconButton
          size="md"
          colorScheme={isVerified ? "green" : "gray"}
          icon={<Icon as={isVerified ? MdCheck : MdBlock} />}
          onClick={handleVerify}
          isDisabled={isDisabled}
        />
      </Tooltip>
    </PermissionedContainer>
  );
};

export default ContactVerify;
