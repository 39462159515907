import React, { Fragment, useCallback, useContext, useMemo } from "react";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { Badge, Box, Center, Checkbox, HStack, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { StatusBadge } from "components";
import { capitalize, currency, percent } from "lib";
import { MdClose } from "react-icons/md";
import { InvoicesSelect } from "./invoicesSelect";
import { TbExternalLink, TbFileInvoice } from "react-icons/tb";
import InstallmentsDetailsContext from "./context";

const Invoices = () => {
  const { formData, setFormData, isChangeable } = useContext(InstallmentsDetailsContext);
  const checks = useMemo(() => {
    const keys = {
      penaltyAmount: { isChecked: false, isIndeterminate: false },
      arrearsAmount: { isChecked: false, isIndeterminate: false },
      monetaryCorrectionAmount: { isChecked: false, isIndeterminate: false },
    };
    for (const key in keys) {
      const values = _.map(formData.invoices, key);
      keys[key].isChecked = _.every(values, (n) => n > 0);
      keys[key].isUnchecked = _.every(values, (n) => n === 0);
      keys[key].isIndeterminate = !keys[key].isChecked && !keys[key].isUnchecked;
    }
    return keys;
  }, [formData.invoices]);

  const handleRemoveInvoice = useCallback((index) => {
    setFormData((state) => {
      const invoices = [...state.invoices];
      invoices.splice(index, 1);
      return { ...state, invoices };
    });
  }, []);

  const handleChangeMonetaryCorrection = useCallback(
    (keys = [], index) =>
      setFormData((state) => {
        const invoices = [...state.invoices];
        const [key] = keys;
        const isChecked = invoices[index][key] === invoices[index]["predicted".concat(capitalize(key))];
        if (isChecked) {
          for (const key of keys) invoices[index][key] = 0;
        } else {
          for (const key of keys) invoices[index][key] = invoices[index]["predicted".concat(capitalize(key))];
        }
        return { ...state, invoices };
      }),
    [setFormData]
  );

  const handleCheckboxHeaderChange = useCallback(
    (...keys) => {
      const [attr] = keys;
      setFormData((state) => {
        const invoices = [...state.invoices];
        for (const invoice of invoices) {
          for (const key of keys) {
            if (checks[attr].isIndeterminate || !checks[attr].isChecked) invoice[key] = invoice["predicted".concat(capitalize(key))];
            else invoice[key] = 0;
          }
        }
        return { ...state, invoices };
      });
    },
    [checks, setFormData]
  );

  return (
    <Fragment>
      <HStack justifyContent="space-between" mb={4}>
        <Text fontSize="sm" fontWeight="semibold">
          {_.size(formData.invoices)} faturas selecionadas
        </Text>
        <InvoicesSelect />
      </HStack>

      {_.size(formData.invoices) === 0 ? (
        <Center paddingTop="40px" paddingBottom="20px">
          <Box textAlign="center">
            <Icon as={TbFileInvoice} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhuma fatura selecionada
            </Text>
            <Text fontSize="sm">Ainda não foram selecionadas faturas para este parcelamento.</Text>
          </Box>
        </Center>
      ) : (
        <Table size="sm" whiteSpace="nowrap">
          <Thead>
            <Tr>
              {isChangeable && <Th>#</Th>}
              <Th>Nid</Th>
              <Th>Status</Th>
              <Th>Cod. referência</Th>
              <Th>N° instalação</Th>
              <Th>Dt. vencimento</Th>
              <Th>Tempo em atraso</Th>
              <Th>
                <HStack>
                  {isChangeable && (
                    <Checkbox
                      colorScheme="main"
                      isChecked={checks.penaltyAmount.isChecked}
                      isIndeterminate={checks.penaltyAmount.isIndeterminate}
                      onChange={() => handleCheckboxHeaderChange("penaltyAmount")}
                    />
                  )}
                  <Text>Multa</Text>
                </HStack>
              </Th>
              <Th>
                <HStack>
                  {isChangeable && (
                    <Checkbox
                      colorScheme="main"
                      isChecked={checks.arrearsAmount.isChecked}
                      isIndeterminate={checks.arrearsAmount.isIndeterminate}
                      onChange={() => handleCheckboxHeaderChange("arrearsAmount", "arrearsPercentage")}
                    />
                  )}
                  <Text>Juros</Text>
                </HStack>
              </Th>
              <Th>
                <HStack>
                  {isChangeable && (
                    <Checkbox
                      colorScheme="main"
                      isChecked={checks.monetaryCorrectionAmount.isChecked}
                      isIndeterminate={checks.monetaryCorrectionAmount.isIndeterminate}
                      onChange={() => handleCheckboxHeaderChange("monetaryCorrectionAmount", "monetaryCorrectionPercentage")}
                    />
                  )}
                  <Text>Correção IPCA</Text>
                </HStack>
              </Th>
              <Th>Valor Click</Th>
              <Th>Total</Th>
              <Th>#</Th>
            </Tr>
          </Thead>
          <Tbody>
            {_.map(formData.invoices, (item, index) => (
              <Tr key={item._id}>
                {isChangeable && (
                  <Td>
                    <IconButton size="xs" colorScheme="red" icon={<Icon as={MdClose} />} onClick={() => handleRemoveInvoice(index)} />
                  </Td>
                )}
                <Td>{item.nid}</Td>
                <Td>
                  <StatusBadge schema="invoices" status={item.status} />
                </Td>
                <Td>{item.referenceCode}</Td>
                <Td>{item.consumerUnit?.cemigInstallationNumber || "-"}</Td>
                <Td>{moment(item.dueDate).format("DD/MM/YYYY")}</Td>
                <Td>{item.overdueDays?.toLocaleString()} dias</Td>
                <Td>
                  {isChangeable ? (
                    <HStack spacing={1}>
                      <Checkbox
                        colorScheme="main"
                        isChecked={item.penaltyAmount === item.predictedPenaltyAmount}
                        onChange={() => handleChangeMonetaryCorrection(["penaltyAmount"], index)}
                      />
                      <Text textDecoration={item.penaltyAmount !== item.predictedPenaltyAmount && "line-through"}>
                        {currency(item.predictedPenaltyAmount)}
                      </Text>
                      <Badge>2%</Badge>
                    </HStack>
                  ) : (
                    <HStack spacing={1}>
                      <Text>{currency(item.penaltyAmount)}</Text>
                      <Badge>{item.penaltyAmount > 0 ? "2%" : "0%"}</Badge>
                    </HStack>
                  )}
                </Td>
                <Td>
                  {isChangeable ? (
                    <HStack spacing={1}>
                      <Checkbox
                        colorScheme="main"
                        isChecked={item.arrearsAmount === item.predictedArrearsAmount}
                        onChange={() => handleChangeMonetaryCorrection(["arrearsAmount", "arrearsPercentage"], index)}
                      />
                      <Text textDecoration={item.arrearsAmount !== item.predictedArrearsAmount && "line-through"}>
                        {currency(item.predictedArrearsAmount)}
                      </Text>
                      <Badge>{percent(item.predictedArrearsPercentage)}</Badge>
                    </HStack>
                  ) : (
                    <HStack spacing={1}>
                      <Text>{currency(item.arrearsAmount)}</Text>
                      <Badge>{percent(item.arrearsPercentage)}</Badge>
                    </HStack>
                  )}
                </Td>
                <Td>
                  {isChangeable ? (
                    <HStack spacing={1}>
                      <Checkbox
                        colorScheme="main"
                        isChecked={item.monetaryCorrectionAmount === item.predictedMonetaryCorrectionAmount}
                        onChange={() => handleChangeMonetaryCorrection(["monetaryCorrectionAmount", "monetaryCorrectionPercentage"], index)}
                      />
                      <Text textDecoration={item.monetaryCorrectionAmount !== item.predictedMonetaryCorrectionAmount && "line-through"}>
                        {currency(item.predictedMonetaryCorrectionAmount)}
                      </Text>
                      <Badge>{percent(item.predictedMonetaryCorrectionPercentage)}</Badge>
                    </HStack>
                  ) : (
                    <HStack spacing={1}>
                      <Text>{currency(item.monetaryCorrectionAmount)}</Text>
                      <Badge>{percent(item.monetaryCorrectionPercentage)}</Badge>
                    </HStack>
                  )}
                </Td>
                <Td>{currency(item.amount)}</Td>
                <Td>{currency(item.amount + item.penaltyAmount + item.arrearsAmount + item.monetaryCorrectionAmount)}</Td>
                <Td>
                  <IconButton
                    size="sm"
                    variant="outline"
                    as={RouterLink}
                    target="_blank"
                    to={`/invoices/details/${item._id}`}
                    icon={<Icon as={TbExternalLink} />}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Fragment>
  );
};

export default Invoices;
