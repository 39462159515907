export const messageTopics = {
  "bankSlip.dueToday": "Lembrete de vencimento",
  "bankSlip.overdue": "Boleto vencido",
  "bankSlip.created": "Boleto emitido",
  "bankSlip.updated": "Boleto atualizado",
  "bankSlip.exempt": "Fatura isenta",
  "bankSlip.canceled": "Boleto cancelado",
  "consumerUnit.statusChanged": "Status da UC alterado",
  "extrajudicialNotice.overdueInvoices": "Notificação extrajudicial",
  "extrajudicialNotice.consumerUnitExclusion": "Notificação de Exclusão do Consórcio de Energia por Inadimplência",
};
