import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import fileDownload from "js-file-download";
import { Parser } from "@json2csv/plainjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SlideFade,
  Spinner,
  StackDivider,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { api } from "lib";
import { Breadcrumb, BoxData, CustomBadge } from "components";
import { useFetchData, useCustomToast, useDocumentTitle, useNewTabNavigate } from "hooks";
import { MdChevronLeft, MdClose, MdOutlineDownload, MdVisibility } from "react-icons/md";
import { Content } from "pages/Private/Container";
import { TbExternalLink, TbFileExport } from "react-icons/tb";

export const InvoiceCapturesDetails = () => {
  const { _id } = useParams();
  useDocumentTitle("Detalhamento da captura de faturas");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData] = useFetchData(useMemo(() => ({ path: `/private/invoice-captures/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [exceptions, setExceptions] = useState();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? {};
    setFormData(formData);
  }, [data]);

  const handleDownloadData = useCallback(async () => {
    try {
      setIsDownloading(true);
      const data = await api({
        method: "post",
        url: `/private/files/${formData.file._id}/object`,
        responseType: "blob",
      });
      fileDownload(data, `${formData.file.title}.${formData.file.ext}`);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsDownloading(false);
    }
  }, [formData]);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/invoice-captures")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "faturas" },
                { to: "/invoice-captures", label: "capturas de faturas" },
                { to: location.pathname, label: "detalhamento" },
              ]}
            />
          </HStack>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Detalhamento da captura de faturas</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id}</Text>
          </Box>
          {formData.file && (
            <Button
              size="sm"
              variant="outline"
              rightIcon={<Icon as={MdOutlineDownload} />}
              isLoading={isDownloading}
              onClick={handleDownloadData}
            >
              baixar arquivo
            </Button>
          )}
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <BoxData label="NID" value={formData.nid ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <BoxData label="Status" value={<CustomBadge.InvoiceCaptures.Status status={formData.status} />} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 8 }}>
            <BoxData label="Usina" value={formData.powerPlant?.name ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <BoxData label="Criado por" value={formData.createdBy?.name ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <BoxData label="Criado em" value={formData.createdAt ? moment(formData.createdAt).format("DD/MM/YYYY [às] HH:mm") : "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <BoxData label="Iniciado em" value={moment(formData.startedAt).format("DD/MM/YYYY [às] HH:mm")} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <BoxData label="Finalizado em" value={moment(formData.finishedAt).format("DD/MM/YYYY [às] HH:mm")} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <BoxData label="Duração" value={`${formData.durationInSeconds || 0} segundos`} />
          </GridItem>
        </Grid>

        {formData.errorMessage && (
          <Alert status="error" borderRadius="lg" mt={4}>
            <AlertDescription fontSize="xs">{formData.errorMessage}</AlertDescription>
          </Alert>
        )}

        <Divider my={8} />

        <Heading size="md" mb={4}>
          Faturas
        </Heading>

        <HStack justifyContent="space-around" divider={<StackDivider />} p="15px" borderWidth="1px" borderRadius="lg">
          <HStack>
            <Box w="40px" h="40px" borderRadius="30px" bg="blue.500" />
            <Box>
              <Text fontSize="sm">Total</Text>
              <Heading size="lg">{formData.invoicesCounters?.size?.toLocaleString() ?? "-"}</Heading>
            </Box>
          </HStack>
          <HStack opacity={formData.invoicesCounters?.fulfilled > 0 ? 1 : 0.2}>
            <Box w="40px" h="40px" borderRadius="30px" bg="green.500" />
            <Box>
              <Text fontSize="sm">Sucessos</Text>
              <Heading size="lg">{formData.invoicesCounters?.fulfilled?.toLocaleString() ?? "-"}</Heading>
            </Box>
          </HStack>
          <HStack opacity={formData.invoicesCounters?.ignored > 0 ? 1 : 0.2}>
            <Box w="40px" h="40px" borderRadius="30px" bg="gray.300" />
            <Box>
              <Text fontSize="sm">Ignoradas</Text>
              <Heading size="lg">{formData.invoicesCounters?.ignored?.toLocaleString() ?? "-"}</Heading>
            </Box>
          </HStack>
          <HStack opacity={formData.invoicesCounters?.rejected > 0 ? 1 : 0.2}>
            <Box w="40px" h="40px" borderRadius="30px" bg="red.500" />
            <Box>
              <Text fontSize="sm">Falhas</Text>
              <Heading size="lg">{formData.invoicesCounters?.rejected?.toLocaleString() ?? "-"}</Heading>
            </Box>
            {_.size(formData.invoicesCounters?.errs) >= 1 && (
              <IconButton
                ml={4}
                size="sm"
                colorScheme="red"
                variant="outline"
                icon={<Icon as={MdVisibility} />}
                onClick={() => setExceptions(formData.invoicesCounters?.errs)}
              />
            )}
          </HStack>
        </HStack>

        <Divider my={8} />

        <Heading size="md" mb={4}>
          Relatórios GD
        </Heading>

        <HStack justifyContent="space-around" divider={<StackDivider />} p="15px" borderWidth="1px" borderRadius="lg">
          <HStack>
            <Box w="40px" h="40px" borderRadius="30px" bg="blue.500" />
            <Box>
              <Text fontSize="sm">Total</Text>
              <Heading size="lg">{formData.electricityBalancesCounters?.size?.toLocaleString() ?? "-"}</Heading>
            </Box>
          </HStack>
          <HStack opacity={formData.electricityBalancesCounters?.fulfilled > 0 ? 1 : 0.2}>
            <Box w="40px" h="40px" borderRadius="30px" bg="green.500" />
            <Box>
              <Text fontSize="sm">Sucessos</Text>
              <Heading size="lg">{formData.electricityBalancesCounters?.fulfilled?.toLocaleString() ?? "-"}</Heading>
            </Box>
          </HStack>
          <HStack opacity={formData.electricityBalancesCounters?.ignored > 0 ? 1 : 0.2}>
            <Box w="40px" h="40px" borderRadius="30px" bg="gray.300" />
            <Box>
              <Text fontSize="sm">Ignoradas</Text>
              <Heading size="lg">{formData.electricityBalancesCounters?.ignored?.toLocaleString() ?? "-"}</Heading>
            </Box>
          </HStack>
          <HStack opacity={formData.electricityBalancesCounters?.rejected > 0 ? 1 : 0.2}>
            <Box w="40px" h="40px" borderRadius="30px" bg="red.500" />
            <Box>
              <Text fontSize="sm">Falhas</Text>
              <Heading size="lg">{formData.electricityBalancesCounters?.rejected?.toLocaleString() ?? "-"}</Heading>
            </Box>
            {_.size(formData.electricityBalancesCounters?.errs) >= 1 && (
              <IconButton
                ml={4}
                size="sm"
                colorScheme="red"
                variant="outline"
                icon={<Icon as={MdVisibility} />}
                onClick={() => setExceptions(formData.electricityBalancesCounters?.errs)}
              />
            )}
          </HStack>
        </HStack>
      </Content>

      <Divider />
      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <Button size="sm" variant="ghost" onClick={() => navigate("/invoice-captures")}>
            voltar
          </Button>
        </HStack>
      </SlideFade>

      <Exceptions exceptions={exceptions} onClose={() => setExceptions()} />
    </>
  );
};

const Exceptions = ({ exceptions, onClose }) => {
  const [isLoadingNavigate, setIsLoadingNavigate] = useState({ referenceCode: {}, installationNumber: {} });
  const newTabNavigate = useNewTabNavigate();

  const handleExportData = useCallback(() => {
    const parser = new Parser({
      fields: [
        { value: "referenceCode", label: "Cód. referência" },
        { value: "installationNumber", label: "N° instalação" },
        { value: "message", label: "Descrição" },
      ],
      delimiter: ";",
      withBOM: true,
    });
    const csv = parser.parse(exceptions);
    fileDownload(csv, `falhas_${new Date().toISOString()}.csv`);
  }, [exceptions]);

  const handleNavigateToCustomer = useCallback(async (key, item) => {
    try {
      setIsLoadingNavigate((state) => ({ ...state, [key]: { ...state[key], [item._id]: true } }));
      const response = await api.post("/private/consumer-units", {
        query: { consumerUnit: { cemigInstallationNumber: item.installationNumber } },
      });
      const [consumerUnit] = response.data;
      switch (key) {
        case "referenceCode":
          newTabNavigate("/invoices", { customer: [{ _id: consumerUnit.customer._id, name: consumerUnit.customer.name }] });
          break;
        case "installationNumber":
          window.open(`/customers/edit/${consumerUnit.customer._id}?consumer_unit=${consumerUnit._id}`);
          break;
      }
    } finally {
      setIsLoadingNavigate((state) => ({ ...state, [key]: { ...state[key], [item._id]: false } }));
    }
  }, []);

  return (
    <Modal size="6xl" isOpen={_.isArray(exceptions)} onClose={onClose} isCentered={true} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack}>
          <Text flex="1">Lista de falhas</Text>
          <IconButton size="sm" variant="outline" icon={<Icon as={TbFileExport} />} onClick={handleExportData} />
          <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
        </ModalHeader>
        <Divider />
        <ModalBody>
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th whiteSpace="nowrap">Cód. referência</Th>
                <Th whiteSpace="nowrap">N° instalação</Th>
                <Th w="100%">Descrição</Th>
              </Tr>
            </Thead>
            <Tbody>
              {_.map(exceptions, (item, index) => (
                <Tr key={index.toString()}>
                  <Td>{index + 1}</Td>
                  <Td whiteSpace="nowrap">
                    <HStack>
                      <Text>{item.referenceCode}</Text>
                      <Tooltip label="Histórico de faturas">
                        <IconButton
                          size="xs"
                          variant="outline"
                          icon={<Icon as={TbExternalLink} />}
                          isLoading={isLoadingNavigate["referenceCode"][item._id]}
                          onClick={() => handleNavigateToCustomer("referenceCode", item)}
                        />
                      </Tooltip>
                    </HStack>
                  </Td>
                  <Td whiteSpace="nowrap">
                    <HStack>
                      <Text>{item.installationNumber}</Text>
                      <Tooltip label="Cadastro da unidade consumidora">
                        <IconButton
                          size="xs"
                          variant="outline"
                          icon={<Icon as={TbExternalLink} />}
                          isLoading={isLoadingNavigate["installationNumber"][item._id]}
                          onClick={() => handleNavigateToCustomer("installationNumber", item)}
                        />
                      </Tooltip>
                    </HStack>
                  </Td>
                  <Td>{item.message}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <VStack alignItems="stretch"></VStack>
        </ModalBody>
        <Divider />
        <ModalFooter as={HStack}>
          <Button size="sm" variant="outline" onClick={onClose}>
            fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
