import React, { useContext, useState, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { HStack, StackDivider, Text, Tooltip, VStack, IconButton, Icon, Box } from "@chakra-ui/react";
import { ErrViewer, StatusBadge } from "components";
import { api, masks } from "lib";
import { alertTopics } from "consts";
import { useNewTabNavigate } from "hooks";
import { TbExternalLink } from "react-icons/tb";
import MessagesDetailsContext from "./context";

const General = () => {
  const { formData } = useContext(MessagesDetailsContext);
  const [isLoadingNavigate, setIsLoadingNavigate] = useState({});
  const newTabNavigate = useNewTabNavigate();

  const handleNavigateToCustomer = useCallback(
    async (key) => {
      try {
        setIsLoadingNavigate((state) => ({ ...state, [key]: true }));
        const response = await api.post("/private/consumer-units", {
          query: { consumerUnit: { cemigInstallationNumber: formData.installationNumber } },
        });
        const [consumerUnit] = response.data;
        switch (key) {
          case "referenceCode":
            newTabNavigate("/invoices", { customer: [{ _id: consumerUnit.customer._id, name: consumerUnit.customer.name }] });
            break;
          case "installationNumber":
            window.open(`/customers/edit/${consumerUnit.customer._id}?consumer_unit=${consumerUnit._id}`);
            break;
        }
      } finally {
        setIsLoadingNavigate((state) => ({ ...state, [key]: false }));
      }
    },
    [formData.installationNumber]
  );

  return (
    <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Status
        </Text>
        <StatusBadge schema="alerts" status={formData.status} />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Classificação
        </Text>
        <StatusBadge schema="alertClassifications" status={formData.classification} />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Tópico
        </Text>
        <Text>{alertTopics[formData.topic] || "-"}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" whiteSpace="nowrap" fontWeight="semibold">
          Descrição
        </Text>
        <Text flex="1" textAlign="right" fontSize="sm">
          {formData.description}
        </Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Código de referência
        </Text>
        <Text>{formData.referenceCode || "-"}</Text>
        <Tooltip label="Histórico de faturas do cliente">
          <IconButton
            size="xs"
            variant="outline"
            icon={<Icon as={TbExternalLink} />}
            isLoading={isLoadingNavigate["referenceCode"]}
            onClick={() => handleNavigateToCustomer("referenceCode")}
          />
        </Tooltip>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Número de instalação
        </Text>
        <Text>{formData.installationNumber || "-"}</Text>
        <Tooltip label="Unidade consumidora">
          <IconButton
            size="xs"
            variant="outline"
            icon={<Icon as={TbExternalLink} />}
            isLoading={isLoadingNavigate["installationNumber"]}
            onClick={() => handleNavigateToCustomer("installationNumber")}
          />
        </Tooltip>
      </HStack>

      {formData.customer && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Cliente
          </Text>
          <Box textAlign="right">
            <Text>{formData.customer?.name || "-"}</Text>
            <Text fontSize="xs">{masks.document(formData.customer?.document || "-")}</Text>
          </Box>
          <IconButton
            as={RouterLink}
            to={`/customers/edit/${formData.customer?._id}`}
            target="_blank"
            size="xs"
            variant="outline"
            icon={<Icon as={TbExternalLink} />}
          />
        </HStack>
      )}

      {formData.solvedAt && (
        <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Solucionado em
            </Text>
            <Text>{moment(formData.solvedAt).format("DD/MM/YYYY [às] HH:mm:ss")}</Text>
          </HStack>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Solucionado por
            </Text>
            <Text>{formData.solvedBy?.name || "-"}</Text>
          </HStack>
        </VStack>
      )}

      {formData.archivedAt && (
        <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Arquivado em
            </Text>
            <Text>{moment(formData.archivedAt).format("DD/MM/YYYY [às] HH:mm:ss")}</Text>
          </HStack>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Arquivado por
            </Text>
            <Text>{formData.archivedBy?.name || "-"}</Text>
          </HStack>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Observações
            </Text>
            <Text flex="2" textAlign="right">
              {formData.archivedComments || "-"}
            </Text>
          </HStack>
        </VStack>
      )}

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Criado em
        </Text>
        <Text>{moment(formData.createdAt).format("DD/MM/YYYY [às] HH:mm:ss")}</Text>
      </HStack>
      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Criado por
        </Text>
        <Text>{formData.createdBy?.name || "-"}</Text>
      </HStack>

      {_.isObject(formData.err) && <ErrViewer err={formData.err} />}
    </VStack>
  );
};

export default General;
