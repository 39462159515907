import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  useColorModeValue,
} from "@chakra-ui/react";
import { api, setOneOrMany } from "lib";
import { AsyncSelect, RangeDateInput } from "components";
import { useCacheState, useStickyState, useNewTabState } from "hooks";
import { MdClose, MdSearch, MdRefresh } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

let loadCustomersTimeout;
let loadUsersTimeout = {};

const Filters = ({ onQuery, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const newTabState = useNewTabState();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: newTabState ?? location.state ?? {},
        useCached: _.isObject(newTabState) === false && _.isObject(location.state) === false,
        _v: 2,
        processor: (data) => ({
          ...data,
          createdAtStart: data.createdAtStart && moment(data.createdAtStart).toDate(),
          createdAtEnd: data.createdAtEnd && moment(data.createdAtEnd).toDate(),
        }),
      }),
      [location.pathname, location.state, newTabState]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    const mapObjectId = (data) => _.map(data, ({ _id }) => ["@ObjectId", _id]);

    setOneOrMany("demandLetter.nid", query.nid, response, (v) => parseInt(v));
    setOneOrMany("consumerUnit.cemigInstallationNumber", query.installationNumber, response, (v) => parseInt(v));

    if (query.type?.length) _.set(response, "demandLetter.type", query.type);
    if (query.createdBy?.length) _.set(response, "demandLetter.createdBy.$in", mapObjectId(query.createdBy));
    if (query.createdAtStart) _.set(response, "demandLetter.createdAt.$gte", ["@ISODate", query.createdAtStart]);
    if (query.createdAtEnd) _.set(response, "demandLetter.createdAt.$lte", ["@ISODate", query.createdAtEnd]);

    if (query.customer?.length) _.set(response, "customer._id.$in", mapObjectId(query.customer));

    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query, location.state]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const response = await api.post("/private/customers", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleLoadUsers = useCallback((key, perPage = 20) => {
    return (search, cb) => {
      clearTimeout(loadUsersTimeout[key]);
      loadUsersTimeout[key] = setTimeout(async () => {
        const response = await api.post("/private/users", { search, perPage, sort: { name: 1 }, isAutocomplete: true });
        cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
      }, 1000);
    };
  }, []);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <HStack spacing={4} wrap={{ base: "wrap", lg: "nowrap" }} direction={{ base: "column", lg: "row" }} mb={8}>
        <HStack w={{ base: "100%", lg: "auto" }}>
          <Button
            flex={{ base: "1", lg: "none" }}
            colorScheme={isFiltering ? "main" : "gray"}
            variant="outline"
            rightIcon={<Icon as={HiOutlineFilter} />}
            onClick={() => setIsOpen((state) => !state)}
          >
            filtros
          </Button>
          {isFiltering && (
            <Button flex={{ base: "1", lg: "none" }} variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
              limpar filtros
            </Button>
          )}
        </HStack>
        <Box minW={{ base: "full", lg: "xs" }}>
          <Input
            placeholder="N° de instalação"
            value={formData.installationNumber ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, installationNumber: target.value }))}
          />
        </Box>
        <Box minW={{ base: "full", lg: "xs" }}>
          <AsyncSelect
            isMulti
            value={formData.customer ?? []}
            defaultOptions
            loadOptions={handleLoadCustomers}
            placeholder="Cliente"
            onChange={(customer) => setFormData((state) => ({ ...state, customer }))}
            getOptionValue={({ _id }) => _id}
            formatOptionLabel={({ name }) => name}
            isClearable={true}
          />
        </Box>
        <HStack flex="1" w={{ base: "100%", lg: "auto" }} justifyContent="flex-end">
          <Button
            flex={{ base: "1", lg: "none" }}
            colorScheme="main"
            rightIcon={<Icon as={MdSearch} />}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            aplicar
          </Button>
          <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
          <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
        </HStack>
      </HStack>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, lg: 2 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  NID
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.nid ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 2 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Tipo
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.type ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="overdue_invoices">Faturas vencidas</option>
                  <option value="consumer_unit_exclusion">Exclusão por inadimplência</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.createdBy ?? []}
                  defaultOptions
                  loadOptions={handleLoadUsers("createdBy")}
                  placeholder="Selecione"
                  onChange={(createdBy) => setFormData((state) => ({ ...state, createdBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.createdAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.createdAtStart}
                    defaultEndDate={formData.createdAtEnd}
                    onChange={(createdAtStart, createdAtEnd) => setFormData((state) => ({ ...state, createdAtStart, createdAtEnd }))}
                  />
                  {formData.createdAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          createdAtStart: null,
                          createdAtEnd: null,
                          createdAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>

          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
