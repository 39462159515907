import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { masks, translator } from "lib";
import { StatusBadge } from "components";
import { TbExternalLink } from "react-icons/tb";
import { Button, HStack, Icon, Link, Text } from "@chakra-ui/react";

const defaultColumns = [
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="distributorMonitorings" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "invoice.customer.name",
    title: "Cliente/Nome",
    isVisible: true,
    isSortable: false,
    formatter: ({ invoice }) => invoice?.customer?.name || "-",
  },
  {
    accessor: "invoice.customer.document",
    title: "Cliente/Documento",
    isVisible: false,
    isSortable: false,
    formatter: ({ invoice }) => masks.document(invoice?.customer?.document || "-"),
  },
  {
    accessor: "consumerUnit.primaryBillingContact.phone",
    title: "Cliente/Telefone faturamento",
    isVisible: false,
    isSortable: false,
    formatter: ({ consumerUnit }) => masks.phone(consumerUnit?.primaryBillingContact?.phone || "-"),
  },
  {
    accessor: "consumerUnit.primaryBillingContact.email",
    title: "Cliente/E-mail faturamento",
    isVisible: false,
    isSortable: false,
    formatter: ({ consumerUnit }) => consumerUnit?.primaryBillingContact?.email || "-",
  },
  {
    accessor: "installationNumber",
    title: "N° instalação",
    isVisible: true,
    formatter: ({ installationNumber }) => installationNumber,
  },
  {
    accessor: "consumerUnit.status.title",
    title: "Status da UC",
    isVisible: true,
    isSortable: false,
    render: ({ consumerUnit }) => <StatusBadge {...consumerUnit?.status} />,
    exporter: ({ consumerUnit }) => translator(consumerUnit?.status?.title),
  },
  {
    accessor: "referenceCode",
    title: "Cod. referência",
    isVisible: true,
    formatter: ({ referenceCode }) => referenceCode,
  },
  {
    accessor: "referenceDate",
    title: "Dt. referência",
    isVisible: true,
    formatter: ({ referenceDate }) => moment(referenceDate).format("MM/YYYY"),
  },
  {
    accessor: "invoice.distributor.current.dueDate",
    title: "Vencimento",
    isVisible: true,
    isSortable: false,
    formatter: ({ invoice }) =>
      invoice?.distributor?.current?.dueDate ? moment(invoice.distributor.current.dueDate).format("DD/MM/YYYY") : "-",
  },
  {
    accessor: "elapsedDays",
    title: "Dias decorridos",
    isVisible: true,
    formatter: ({ elapsedDays }) => elapsedDays?.toLocaleString(),
  },
  {
    accessor: "paidAt",
    title: "Pago em",
    isVisible: true,
    formatter: ({ paidAt }) => (paidAt ? moment(paidAt).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "invoice.nid",
    title: "Fatura/NID",
    isVisible: true,
    isSortable: false,
    render: ({ invoice }) =>
      invoice ? (
        <Button
          size="xs"
          variant="outline"
          rightIcon={<Icon as={TbExternalLink} />}
          as={RouterLink}
          to={`/invoices/details/${invoice._id}`}
          target="_blank"
        >
          <HStack>
            <StatusBadge schema="invoices" status={invoice.status} />
            <Text>#{invoice.nid}</Text>
          </HStack>
        </Button>
      ) : (
        "-"
      ),
    exporter: ({ invoice }) => invoice?.nid || "-",
  },
  {
    accessor: "invoice.status",
    title: "Fatura/Status",
    isVisible: false,
    isSortable: false,
    render: ({ invoice }) => <StatusBadge schema="invoices" status={invoice.status} />,
    exporter: ({ invoice }) => translator(invoice?.status),
  },
  {
    accessor: "invoice.isUnified",
    title: "Modalidade de fatura",
    isVisible: true,
    isSortable: false,
    formatter: ({ invoice }) => (invoice ? (invoice.isUnified ? "Fatura unificada" : "Fatura separada") : "-"),
  },
  // {
  //   accessor: "messagedAt",
  //   title: "Dt. último envio",
  //   isVisible: true,
  //   formatter: ({ messagedAt }) => (messagedAt ? moment(messagedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
  // },
  {
    accessor: "archivedBy",
    title: "Arquivado por",
    isVisible: true,
    formatter: ({ archivedBy }) => archivedBy?.name || "-",
  },
  {
    accessor: "archivedAt",
    title: "Arquivado em",
    isVisible: true,
    formatter: ({ archivedAt }) => (archivedAt ? moment(archivedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
  },
  {
    accessor: "archivedComments",
    title: "Observações",
    isVisible: false,
    formatter: ({ archivedComments }) => archivedComments || "-",
  },
  {
    accessor: "comments",
    title: "Observações",
    isVisible: false,
    formatter: ({ comments }) => comments || "-",
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name || "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY [às] HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY [às] HH:mm"),
  },
];

export default defaultColumns;
