import React, { Fragment, useCallback, useContext, useMemo } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Select,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Address, AsyncSelect, BoxData, EmailAutocomplete, InputCurrency, PhoneInput } from "components";
import { useArrayItemHandlers, usePermissioned, useFetchData } from "hooks";
import { personTypes, installationTypes, loyaltyTimesInMonths, statuses } from "consts";
import { api, translator } from "lib";
import { MdAddCircleOutline, MdOutlineDelete, MdRefresh } from "react-icons/md";
import LeadsDetailsContext from "../context";
import moment from "moment";

let loadCustomersTimeout, loadUsersTimeout;

const General = () => {
  const { _id } = useParams();
  const { formData, setFormData, formErrors, isClosing, isEditDisabled } = useContext(LeadsDetailsContext);
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const [exhibitions, isLoadingExhibitions, refreshExhibitions] = useFetchData(
    useMemo(() => ({ path: "/private/exhibitions", params: { query: { isActive: true }, isAutocomplete: true } }), [])
  );
  const isAllowedLeadsRead = usePermissioned("leads.read");

  const handleLoadCustomers = useCallback(
    (search, cb) => {
      clearTimeout(loadCustomersTimeout);
      loadCustomersTimeout = setTimeout(async () => {
        const query = { _id: { $ne: _id }, isActive: true };
        const response = await api.post("/private/customers", { search, query, perPage: -1, isAutocomplete: true });
        cb(response?.data ?? []);
      }, 1000);
    },
    [_id]
  );

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const query = { isActive: true };
      const response = await api.post("/private/users", { search, query, perPage: -1, isAutocomplete: true });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <BoxData label="NID" value={formData.nid ?? "-"} />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <BoxData label="Plataforma" value={formData.platform ?? "-"} />
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.consultant}>
            <FormLabel fontSize="sm">Consultor</FormLabel>
            <AsyncSelect
              value={formData.consultant ?? {}}
              defaultOptions
              loadOptions={handleLoadUsers}
              placeholder="Selecione o consultor"
              onChange={(consultant) => setFormData((state) => ({ ...state, consultant }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name }) => name}
              isDisabled={isEditDisabled("consultant")}
            />
            <FormErrorMessage>{formErrors.consultant}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <FormControl isInvalid={formErrors.status}>
            <FormLabel fontSize="sm">Status</FormLabel>
            <Select
              value={formData.status ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}
              isDisabled={isEditDisabled("status")}
            >
              {statuses.leads.map((item) => (
                <option key={item.value} value={item.value}>
                  {translator(item.value)}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formErrors.status}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <FormControl isRequired={true} isInvalid={formErrors.type}>
            <FormLabel fontSize="sm">Tipo de pessoa</FormLabel>
            <Select
              value={formData.type ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}
              isDisabled={isEditDisabled("type")}
            >
              <option value="pf">Pessoa física</option>
              <option value="pj">Pessoa jurídica</option>
            </Select>
            <FormErrorMessage>{formErrors.type}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 12 }}>
          <FormControl isRequired={true} isInvalid={formErrors.name}>
            <FormLabel fontSize="sm">{personTypes[formData.type]?.name}</FormLabel>
            <Input
              value={formData.name ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))}
              isDisabled={isEditDisabled("name")}
            />
            <FormErrorMessage>{formErrors.name}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.exhibition}>
            <FormLabel fontSize="sm">Evento</FormLabel>
            <HStack>
              <Select
                value={formData.exhibition ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, exhibition: target.value }))}
                isDisabled={isEditDisabled("exhibition")}
              >
                <option>--Selecione</option>
                {_.map(exhibitions?.data, (item) => (
                  <option key={item._id} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </Select>
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingExhibitions} onClick={refreshExhibitions} />
            </HStack>
            <FormErrorMessage>{formErrors.exhibition}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={isClosing} isInvalid={formErrors.document}>
            <FormLabel fontSize="sm">{personTypes[formData.type]?.document}</FormLabel>
            <Input
              as={InputMask}
              mask={personTypes[formData.type]?.documentMask}
              value={formData.document ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
              isDisabled={isEditDisabled("document")}
            />
            <FormErrorMessage>{formErrors.document}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={isClosing} isInvalid={formErrors.email}>
            <FormLabel fontSize="sm">E-mail</FormLabel>
            <EmailAutocomplete
              value={formData.email ?? ""}
              onChange={(email) => setFormData((state) => ({ ...state, email }))}
              isInvalid={formErrors.email}
            />
            <FormErrorMessage>{formErrors.email}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={true} isInvalid={formErrors.phone}>
            <FormLabel fontSize="sm">Telefone</FormLabel>
            <PhoneInput
              phone={formData.phone}
              category={formData.phoneCategory}
              onChangePhone={(phone) => setFormData((state) => ({ ...state, phone }))}
              onChangeCategory={(phoneCategory) => setFormData((state) => ({ ...state, phoneCategory }))}
              isDisabled={isEditDisabled("phone")}
            />
            <FormErrorMessage>{formErrors.phone}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isInvalid={formErrors.indicatedBy}>
            <FormLabel fontSize="sm">Indicado por</FormLabel>
            <AsyncSelect
              value={formData.indicatedBy ?? {}}
              defaultOptions
              loadOptions={handleLoadCustomers}
              placeholder="Selecione o cliente"
              onChange={(indicatedBy) => setFormData((state) => ({ ...state, indicatedBy }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name }) => name}
              isClearable={true}
              isDisabled={isEditDisabled("indicatedBy")}
            />
            <FormErrorMessage>{formErrors.indicatedBy}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Box mb={6}>
        <Heading size="sm">Unidades consumidoras</Heading>
        {_.isString(formErrors.consumerUnits) && (
          <Text fontSize="sm" color="red.500">
            {formErrors.consumerUnits}
          </Text>
        )}
      </Box>
      <VStack align="stretch" divider={<StackDivider />} spacing={8}>
        {_.map(formData.consumerUnits, (consumerUnit, index) => (
          <Grid key={index} templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl isRequired={isClosing} isInvalid={formErrors.consumerUnits?.[index].installationNumber}>
                <FormLabel fontSize="sm">Número de instalação</FormLabel>
                <Input
                  value={consumerUnit.installationNumber ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("consumerUnits", index, { installationNumber: target.value })}
                  isDisabled={isEditDisabled("consumerUnits")}
                />
                <FormErrorMessage>{formErrors.consumerUnits?.[index].installationNumber}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl isRequired={isClosing} isInvalid={formErrors.consumerUnits?.[index].customerClass}>
                <FormLabel fontSize="sm">Classe do cliente</FormLabel>
                <Select
                  value={consumerUnit.customerClass ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("consumerUnits", index, { customerClass: target.value })}
                  isDisabled={isEditDisabled("consumerUnits")}
                >
                  <option value="">--Selecione</option>
                  {["comercial", "residencial", "rural", "industrial"].map((item) => (
                    <option key={item} value={item}>
                      {item.toUpperCase()}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{formErrors.consumerUnits?.[index].customerClass}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl isRequired={isClosing} isInvalid={formErrors.consumerUnits?.[index].electricityBillAmount}>
                <FormLabel fontSize="sm">Dia venc. da conta</FormLabel>
                <Input
                  as={InputCurrency}
                  value={consumerUnit.invoiceDueDay ?? ""}
                  onChange={(invoiceDueDay) => handleChangeArrayItem("consumerUnits", index, { invoiceDueDay })}
                  isDisabled={isEditDisabled("consumerUnits")}
                  precision="0"
                />
                <FormErrorMessage>{formErrors.consumerUnits?.[index].electricityBillAmount}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl isRequired={isClosing} isInvalid={formErrors.consumerUnits?.[index].electricityBillAmount}>
                <FormLabel fontSize="sm">Valor da conta</FormLabel>
                <InputGroup>
                  <InputLeftAddon>R$</InputLeftAddon>
                  <Input
                    as={InputCurrency}
                    value={consumerUnit.electricityBillAmount ?? ""}
                    onChange={(electricityBillAmount) => handleChangeArrayItem("consumerUnits", index, { electricityBillAmount })}
                    isDisabled={isEditDisabled("consumerUnits")}
                  />
                </InputGroup>
                <FormErrorMessage>{formErrors.consumerUnits?.[index].electricityBillAmount}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 8, lg: 3 }}>
              <FormControl isRequired={isClosing} isInvalid={formErrors.consumerUnits?.[index].electricityAverageSpentInKwh}>
                <FormLabel fontSize="sm">Média de consumo</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    precision="0"
                    value={consumerUnit.electricityAverageSpentInKwh ?? ""}
                    onChange={(electricityAverageSpentInKwh) =>
                      handleChangeArrayItem("consumerUnits", index, { electricityAverageSpentInKwh })
                    }
                    isDisabled={isEditDisabled("consumerUnits")}
                  />
                  <InputRightElement fontSize="xs">kWh</InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formErrors.consumerUnits?.[index].electricityAverageSpentInKwh}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl isRequired={isClosing} isInvalid={formErrors.consumerUnits?.[index]?.installationType}>
                <FormLabel fontSize="sm">Tipo de instalação</FormLabel>
                <Select
                  value={consumerUnit.installationType ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("consumerUnits", index, { installationType: target.value })}
                  isDisabled={isEditDisabled("consumerUnits")}
                >
                  <option value="">--Selecione</option>
                  {installationTypes.map((item) => (
                    <option key={item} value={item}>
                      {item.toUpperCase()}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{formErrors.consumerUnits?.[index].installationType}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl isRequired={isClosing} isInvalid={formErrors.consumerUnits?.[index].agreedDiscount}>
                <FormLabel fontSize="sm">Desconto acordado</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    value={consumerUnit.agreedDiscount ?? ""}
                    onChange={(agreedDiscount) => handleChangeArrayItem("consumerUnits", index, { agreedDiscount })}
                    isPercentage={true}
                    isDisabled={isEditDisabled("consumerUnits")}
                  />
                  <InputRightElement fontSize="xs">%</InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formErrors.consumerUnits?.[index].agreedDiscount}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl isRequired={isClosing} isInvalid={formErrors.consumerUnits?.[index].loyaltyTimeInMonths}>
                <FormLabel fontSize="sm">Tempo de fidelidade</FormLabel>
                <HStack>
                  <Select
                    value={consumerUnit.loyaltyTimeInMonths ?? ""}
                    onChange={({ target }) => handleChangeArrayItem("consumerUnits", index, { loyaltyTimeInMonths: target.value })}
                    isDisabled={isEditDisabled("consumerUnits")}
                  >
                    <option value="">--Selecione</option>
                    {loyaltyTimesInMonths.map((item) => (
                      <option key={item} value={item}>
                        {item} meses
                      </option>
                    ))}
                  </Select>
                  <IconButton
                    size="md"
                    variant="outline"
                    icon={<Icon as={MdOutlineDelete} />}
                    onClick={() => handleDeleteArrayItem("consumerUnits", index)}
                    isDisabled={isEditDisabled("consumerUnits")}
                  />
                </HStack>
                <FormErrorMessage>{formErrors.consumerUnits?.[index].loyaltyTimeInMonths}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        ))}
      </VStack>
      <Button
        size="sm"
        colorScheme="main"
        variant="ghost"
        mt={4}
        leftIcon={<Icon as={MdAddCircleOutline} />}
        onClick={() => handleAddArrayItem("consumerUnits", {})}
        isDisabled={isEditDisabled("consumerUnits")}
      >
        adicionar unidade consumidora
      </Button>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Endereço
      </Heading>
      <Address
        data={formData.address}
        errors={formErrors.address}
        isRequired={true}
        onChange={(address) => setFormData((state) => ({ ...state, address: { ...state.address, ...address } }))}
        isDisabled={isEditDisabled("address")}
      />

      <Divider my={8} />

      <Heading size="md" mb={4}>
        UTM
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        {["utm_medium", "utm_source", "utm_id", "utm_content", "utm_term", "utm_campaign"].map((key) => (
          <GridItem key={key} colSpan={{ base: 12, lg: 4 }}>
            <FormControl isRequired={isClosing} isInvalid={formErrors[key]}>
              <FormLabel fontSize="sm">{key}</FormLabel>
              <Input
                value={formData[key] ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, [key]: target.value }))}
                isDisabled={isEditDisabled("utms")}
              />
              <FormErrorMessage>{formErrors[key]}</FormErrorMessage>
            </FormControl>
          </GridItem>
        ))}
      </Grid>

      <Divider my={8} />

      <Heading size="md" mb={4}>
        Kommo
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <Text fontSize="xs">ID</Text>
          <Text>{formData.kommo?.id}</Text>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <Text fontSize="xs">Status</Text>
          <Text>{formData.kommo?.status}</Text>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <Text fontSize="xs">Criado em</Text>
          <Text>{moment(formData.kommo?.createdAt).format("DD/MM/YYYY HH:mm")}</Text>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <Text fontSize="xs">Atualizado em</Text>
          <Text>{moment(formData.kommo?.updatedAt).format("DD/MM/YYYY HH:mm")}</Text>
        </GridItem>
      </Grid>

      {_.size(formData.visitedUrls) >= 1 && (
        <Fragment>
          <Divider my={8} />

          <Heading size="md" mb={4}>
            URLs de visitas do usuário
          </Heading>

          <VStack alignItems="stretch" divider={<StackDivider />}>
            {_.map(formData.visitedUrls, (item) => (
              <Box key={item._id}>
                <Text fontSize="sm">{item.url}</Text>
                <Text fontSize="xs">Visita em {moment(item.settedAt).format("DD/MM/YYYY [às] HH:mm:ss")}</Text>
              </Box>
            ))}
          </VStack>
        </Fragment>
      )}
    </>
  );
};

export default General;
