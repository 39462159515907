import React, { useCallback } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { EmailAutocomplete, PhoneInput } from "components";
import { MdAdd, MdCopyAll, MdDeleteOutline } from "react-icons/md";
import { TiContacts } from "react-icons/ti";
import ContactVerify from "../ContactVerify";
import { FiClipboard } from "react-icons/fi";
import { useClipboard } from "hooks";

const BillingContact = ({ formData, setFormData, formErrors, isEditDisabled }) => {
  const copyToClipboard = useClipboard();

  const handleCopyHolderContact = useCallback((index) => {
    setFormData((state) => {
      const billingContacts = [...state.billingContacts];
      billingContacts[index] = {
        name: state.name,
        sendingMethod: "email",
        phone: state.phone1,
        phoneCategory: state.phone1Category,
        email: state.email,
      };
      return { ...state, billingContacts };
    });
  }, []);

  const handleAddContact = useCallback(() => {
    setFormData((state) => {
      const billingContacts = [...state.billingContacts];
      billingContacts.push({ phoneCategory: "whatsapp" });
      return { ...state, billingContacts };
    });
  }, []);

  const handleRemoveContact = useCallback((index) => {
    setFormData((state) => {
      const billingContacts = [...state.billingContacts];
      billingContacts.splice(index, 1);
      return { ...state, billingContacts };
    });
  }, []);

  const handleChange = useCallback((index, obj) => {
    setFormData((state) => {
      const billingContacts = [...state.billingContacts];
      billingContacts[index] = { ...billingContacts[index], ...obj };
      return { ...state, billingContacts };
    });
  }, []);

  return (
    <Box {...(_.isString(formErrors.billingContacts) && { borderWidth: "2px", borderColor: "red.500", p: "15px", borderRadius: "lg" })}>
      <HStack mb={6}>
        <Heading size="sm" flex="1">
          Contatos para faturamento
        </Heading>
        <Button
          size="sm"
          variant="outline"
          leftIcon={<Icon as={MdAdd} />}
          onClick={handleAddContact}
          isDisabled={isEditDisabled("billingContacts")}
        >
          adicionar contato
        </Button>
      </HStack>
      <VStack spacing={4} alignItems="stretch">
        {_.map(formData.billingContacts, (item, index) => (
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl isRequired={true} isInvalid={formErrors.billingContacts?.[index]?.name}>
                <FormLabel fontSize="sm">Nome</FormLabel>
                <Input
                  value={item.name ?? ""}
                  onChange={({ target }) => handleChange(index, { name: target.value })}
                  isDisabled={isEditDisabled("billingContacts")}
                />
                <FormErrorMessage>{formErrors.billingContacts?.[index]?.name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl isRequired={true} isInvalid={formErrors.billingContacts?.[index]?.sendingMethod}>
                <FormLabel fontSize="sm">Forma de envio</FormLabel>
                <Select
                  value={item.sendingMethod ?? ""}
                  onChange={({ target }) => handleChange(index, { sendingMethod: target.value })}
                  isDisabled={isEditDisabled("billingContacts")}
                >
                  <option value="">--Selecione</option>
                  <option value="email">E-mail</option>
                  <option value="whatsapp">WhatsApp</option>
                </Select>
                <FormErrorMessage>{formErrors.billingContacts?.[index]?.sendingMethod}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              {item.sendingMethod === "email" ? (
                <FormControl isRequired={true} isInvalid={formErrors.billingContacts?.[index]?.email}>
                  <HStack mb="0.3em">
                    <Tooltip label="Copiar e-mail.">
                      <IconButton
                        size="xs"
                        variant="outline"
                        icon={<Icon as={FiClipboard} />}
                        onClick={() => copyToClipboard(item.email)}
                      />
                    </Tooltip>
                    <FormLabel fontSize="sm" mb="0">
                      E-mail
                    </FormLabel>
                  </HStack>
                  <HStack>
                    <EmailAutocomplete
                      value={item.email ?? ""}
                      onChange={(email) => handleChange(index, { email })}
                      isInvalid={formErrors.billingContacts?.[index]?.email}
                      isDisabled={isEditDisabled("billingContacts")}
                    />
                    <ContactVerify
                      type="email"
                      value={item.email}
                      isVerified={item.emailVerified}
                      isDisabled={isEditDisabled("billingContacts")}
                    />
                  </HStack>
                  <FormErrorMessage>{formErrors.billingContacts?.[index]?.email}</FormErrorMessage>
                </FormControl>
              ) : (
                item.sendingMethod === "whatsapp" && (
                  <FormControl isRequired={true} isInvalid={formErrors.billingContacts?.[index]?.phone}>
                    <HStack mb="0.3em">
                      <Tooltip label="Copiar telefone.">
                        <IconButton
                          size="xs"
                          variant="outline"
                          icon={<Icon as={FiClipboard} />}
                          onClick={() => copyToClipboard(item.phone)}
                        />
                      </Tooltip>
                      <FormLabel fontSize="sm" mb="0">
                        Telefone
                      </FormLabel>
                    </HStack>
                    <HStack>
                      <PhoneInput
                        phone={item.phone}
                        category={item.phoneCategory}
                        onChangePhone={(phone) => handleChange(index, { phone })}
                        onChangeCategory={(phoneCategory) => handleChange(index, { phoneCategory })}
                        isDisabled={isEditDisabled("billingContacts")}
                      />
                      <ContactVerify
                        type="phone"
                        value={item.phone}
                        isVerified={item.phoneVerified}
                        isDisabled={isEditDisabled("billingContacts")}
                      />
                    </HStack>
                    <FormErrorMessage>{formErrors.billingContacts?.[index]?.phone}</FormErrorMessage>
                  </FormControl>
                )
              )}
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="sm">&nbsp;</FormLabel>
                {index >= 1 ? (
                  <Button
                    w="100%"
                    variant="outline"
                    leftIcon={<Icon as={MdDeleteOutline} />}
                    onClick={() => handleRemoveContact(index)}
                    isDisabled={isEditDisabled("billingContacts")}
                  >
                    excluir
                  </Button>
                ) : (
                  <Button
                    w="100%"
                    variant="outline"
                    rightIcon={<Icon as={MdCopyAll} />}
                    onClick={() => handleCopyHolderContact(index)}
                    isDisabled={isEditDisabled("billingContacts")}
                  >
                    mesmo do principal
                  </Button>
                )}
              </FormControl>
            </GridItem>
          </Grid>
        ))}
      </VStack>
      {_.size(formData.billingContacts) === 0 && (
        <Center paddingTop="40px" paddingBottom="60px">
          <Box textAlign="center">
            <Icon as={TiContacts} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhum contato de faturamento
            </Text>
            <Text fontSize="sm">Ainda não foram cadastrados contatos de faturamento para este cliente.</Text>
          </Box>
        </Center>
      )}
    </Box>
  );
};

export default BillingContact;
