import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { Box, Button, HStack, Icon, IconButton, StackDivider, Text, VStack } from "@chakra-ui/react";
import { ErrViewer, StatusBadge } from "components";
import { useQueryParams } from "hooks";
import { TbExternalLink } from "react-icons/tb";
import MessagesDetailsContext from "./context";
import { masks, translator } from "lib";
import { messageTopics } from "consts";

const General = () => {
  const { formData } = useContext(MessagesDetailsContext);
  const { setQueryParams } = useQueryParams();

  return (
    <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Status da verificação
        </Text>
        <StatusBadge schema="messagesVerification" status={formData.verificationStatus} />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Status do envio
        </Text>
        <StatusBadge schema="messages" status={formData.status} />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Canal
        </Text>
        <Text>{translator(formData.channel)}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Tópico
        </Text>
        <Text>{messageTopics[formData.topic] || "-"}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Destinatário
        </Text>
        <Text>{formData.channel === "whatsapp" ? masks.phone(formData.contactTarget) : formData.contactTarget}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Cliente
        </Text>
        <Box flex="1" textAlign="right">
          <Text>{formData.customer?.name || "-"}</Text>
          <Text fontSize="xs">{masks.document(formData.customer?.document || "-")}</Text>
        </Box>
        <IconButton
          size="sm"
          variant="outline"
          as={RouterLink}
          icon={<Icon as={TbExternalLink} />}
          to={`/customers/edit/${formData.customer?._id}`}
          target="_blank"
        />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Unidade consumidora
        </Text>
        <Text>{formData.consumerUnit?.cemigInstallationNumber || "-"}</Text>
        <IconButton
          size="sm"
          variant="outline"
          as={RouterLink}
          icon={<Icon as={TbExternalLink} />}
          to={`/customers/edit/${formData.customer?._id}?consumer_unit=${formData.consumerUnit?._id}`}
          target="_blank"
        />
      </HStack>

      {formData.invoice && (
        <VStack fontSize="sm" p="20px" borderWidth="1px" borderRadius="lg" divider={<StackDivider />} alignItems="stretch" spacing={4}>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Fatura
            </Text>
            <Button
              size="sm"
              variant="outline"
              as={RouterLink}
              rightIcon={<Icon as={TbExternalLink} />}
              to={`/invoices/details/${formData.invoice?._id}`}
              target="_blank"
            >
              <HStack>
                <StatusBadge schema="invoices" status={formData.invoice?.status} />
                <Text>#{formData.invoice?.nid || "-"}</Text>
              </HStack>
            </Button>
          </HStack>

          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Cobrança
            </Text>
            <Button
              size="sm"
              variant="outline"
              rightIcon={<Icon as={TbExternalLink} />}
              onClick={() => setQueryParams((params) => ({ ...params, charge_id: formData.charge?._id }))}
            >
              <HStack>
                <StatusBadge schema="charges" status={formData.charge?.status} />
                <Text>#{formData.charge?.nid || "-"}</Text>
              </HStack>
            </Button>
          </HStack>

          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Data de vencimento da fatura
            </Text>
            <Text>{formData.invoice?.dueDate ? moment(formData.invoice.dueDate).format("DD/MM/YYYY") : "-"}</Text>
          </HStack>

          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Modalidade de fatura
            </Text>
            <Text>
              {_.isBoolean(formData.invoice?.isUnified) ? (formData.invoice.isUnified ? "Fatura unificada" : "Fatura separada") : "-"}
            </Text>
          </HStack>

          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Código de referência da fatura
            </Text>
            <Text>{formData.invoice?.referenceCode}</Text>
          </HStack>

          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Data de referência da fatura
            </Text>
            <Text>{moment(formData.invoice?.referenceDate).format("MM/YYYY")}</Text>
          </HStack>
        </VStack>
      )}

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Criada em
        </Text>
        <Box flex="1" textAlign="right">
          <Text>{moment(formData.createdAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
          <Text fontSize="xs">
            por <strong>{formData.createdBy?.name || "-"}</strong>
          </Text>
        </Box>
      </HStack>

      {formData.sentAt && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Enviada em
          </Text>
          <Text>{moment(formData.sentAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
        </HStack>
      )}

      {formData.finishedAt && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Finalizada em
          </Text>
          <Text>{moment(formData.finishedAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
        </HStack>
      )}

      {formData.archivedAt && (
        <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Arquivado em
            </Text>
            <Text>{moment(formData.archivedAt).format("DD/MM/YYYY")}</Text>
          </HStack>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Arquivado por
            </Text>
            <Text>{formData.archivedBy?.name || "-"}</Text>
          </HStack>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Observações do arquivamento
            </Text>
            <Text flex="2" textAlign="right">
              {formData.archivedComments || "-"}
            </Text>
          </HStack>
        </VStack>
      )}

      {_.size(formData.errs) >= 1 && (
        <VStack alignItems="stretch">
          {_.map(formData.errs, (err) => (
            <ErrViewer key={err._id} err={err} />
          ))}
        </VStack>
      )}
    </VStack>
  );
};

export default General;
