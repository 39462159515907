import React, { useState, useEffect, useMemo, useCallback, memo, useContext } from "react";
import _ from "lodash";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  SlideFade,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { MdChevronLeft, MdError, MdHistory } from "react-icons/md";
import { TbExternalLink } from "react-icons/tb";
import { Breadcrumb, PermissionedContainer } from "components";
import { DocumentHistory } from "containers";
import { useFetchData, useCustomToast, useDocumentTitle, usePermissioned } from "hooks";
import { messages } from "consts";
import { api, yup } from "lib";
import { Content } from "pages/Private/Container";
import { PrivateContext } from "pages/Private";
import General from "./general";
import Files from "./files";
import LeadsDetailsContext from "./context";

const CustomTab = memo(({ title, isLoading, count, RightComponent }) => {
  return (
    <Tab>
      <HStack>
        <Text>{title}</Text>
        {isLoading ? (
          <Spinner size="xs" />
        ) : (
          RightComponent ??
          (_.isNumber(count) && (
            <Text fontSize="sm" fontWeight="semibold">
              ({count})
            </Text>
          ))
        )}
      </HStack>
    </Tab>
  );
});

export const LeadsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar lead" : "Novo lead");
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(PrivateContext);
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/leads/${_id}` }), [_id]));
  const [files, isLoadingFiles, refreshFiles] = useFetchData(
    useMemo(() => {
      const params = { query: { owner: _id }, perPage: -1, sort: { title: "asc" } };
      return { path: `/private/files`, params, options: { isEnabled: !!_id } };
    }, [_id])
  );
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [isSomeFileGroupInvalid, setIsSomeFileGroupInvalid] = useState(false);
  const isClosing = useMemo(() => formData.status === "negotiation", [formData.status]);
  const { isOpen: isOpenSubmitConfirmDialog, onOpen: onOpenSubmitConfirmDialog, onClose: onCloseSubmitConfirmDialog } = useDisclosure();
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const isAllowed = { leadsUpdate: usePermissioned("leads.update.*") };
  const toast = useCustomToast();

  useEffect(() => {
    if (_.isUndefined(data) === false) {
      const formData = data ?? {
        status: "prospection",
        type: "pf",
        phoneCategory: "whatsapp",
        platform: "dash",
      };
      if (formData.status === "prospection" && !formData.consultant) formData.consultant = { _id: currentUser._id, name: currentUser.name };
      setFormData(formData);
    }
  }, [data]);

  const isEditDisabled = useCallback(
    (key) => {
      if (_.isString(_id)) return !isAllowed.leadsUpdate[key];
      return false;
    },
    [_id, isAllowed.leadsUpdate]
  );

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/private/leads/${_id}`, data) : await api.put("/private/leads", data);
        if (isClosing) navigate(`/customers/edit/${saved.customer}`);
        else {
          navigate(`/leads/edit/${saved._id}`, { replace: true });
          refreshData();
        }
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, isClosing, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      onCloseSubmitConfirmDialog();
      const consumerUnitSchema = yup.object().shape({
        electricityBillAmount: yup.number().moreThan(0, messages.error.required).required(messages.error.required),
        electricityAverageSpentInKwh: yup.number().moreThan(0, messages.error.required).required(messages.error.required),
        installationType: yup.string().required(messages.error.required),
        loyaltyTimeInMonths: yup.string().required(messages.error.required),
        agreedDiscount: yup.number().moreThan(0, messages.error.moreThan.concat("0.")).required(messages.error.required),
      });
      let shape = {
        name: yup.string().isValidName().required(messages.error.required),
        phone: yup.string().required(messages.error.required).isValidPhoneNumber(),
        consultant: yup.string().required(messages.error.required),
      };
      if (formData.status === "negotiation")
        shape = {
          ...shape,
          email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
          document: yup.string().required(messages.error.required),
          address: yup.object().shape({
            zipCode: yup.string().required(messages.error.required),
            street: yup.string().required(messages.error.required),
            number: yup.string().required(messages.error.required),
            neighborhood: yup.string().required(messages.error.required).max(20, messages.error.maxLength(20)),
            city: yup.string().required(messages.error.required),
            state: yup.string().required(messages.error.required),
          }),
          consumerUnits: yup.array().of(consumerUnitSchema).min(1, messages.error.required).required(messages.error.required),
        };
      const schema = yup.object().shape(shape);
      const data = { ...formData, indicatedBy: formData.indicatedBy?._id ?? null, consultant: formData.consultant?._id };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData, onCloseSubmitConfirmDialog]);

  const handleSubmitConfirm = useCallback(() => {
    if (isClosing) return onOpenSubmitConfirmDialog();
    return handleSubmit();
  }, [isClosing, handleSubmit, onOpenSubmitConfirmDialog]);

  return (
    <LeadsDetailsContext.Provider
      value={{
        isLoadingData,
        formData,
        setFormData,
        formErrors,
        files,
        isLoadingFiles,
        refreshFiles,
        isSomeFileGroupInvalid,
        setIsSomeFileGroupInvalid,
        isClosing,
        isEditDisabled,
      }}
    >
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/leads")}>
              voltar
            </Button>
            <Breadcrumb
              items={[{ label: "cadastros" }, { to: "/leads", label: "leads" }, { to: location.pathname, label: _id ? "editar" : "novo" }]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Lead</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.name : "Novo cadastro"}</Text>
          </Box>
          {formData.customer && (
            <Button
              size="sm"
              variant="outline"
              rightIcon={<Icon as={TbExternalLink} />}
              as={RouterLink}
              to={`/customers/edit/${formData.customer}`}
              target="_blank"
            >
              cliente gerado
            </Button>
          )}
        </HStack>

        <Tabs colorScheme="main">
          <TabList>
            <CustomTab title="dados gerais" />
            {!formData.isClosed && (
              <PermissionedContainer required="leads.files.read">
                <CustomTab
                  title="arquivos"
                  isLoading={isLoadingFiles}
                  count={_.size(files?.data)}
                  RightComponent={
                    isSomeFileGroupInvalid && (
                      <Tooltip label="O autor ainda não adicionou os documentos deste lead.">
                        <IconButton size="xs" colorScheme="red" icon={<Icon as={MdError} />} isRound />
                      </Tooltip>
                    )
                  }
                />
              </PermissionedContainer>
            )}
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Files />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Content>

      <PermissionedContainer required={"leads.".concat(_id ? "update.*" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button
              size="sm"
              colorScheme="main"
              isDisabled={data?.isClosed}
              isLoading={isLoadingData || isLoadingSaveData}
              onClick={handleSubmitConfirm}
            >
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate("/leads")}>
              cancelar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      <AlertDialog isOpen={isOpenSubmitConfirmDialog} onClose={onCloseSubmitConfirmDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Atenção
            </AlertDialogHeader>
            <AlertDialogBody>
              Esta é uma ação irreversível. Você está transformando este lead em um cliente. Deseja realmente continuar?
            </AlertDialogBody>
            <AlertDialogFooter as={HStack}>
              <Button onClick={onCloseSubmitConfirmDialog}>cancelar</Button>
              <Button colorScheme="yellow" onClick={handleSubmit}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {_id && <DocumentHistory path={`/private/leads/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
    </LeadsDetailsContext.Provider>
  );
};
