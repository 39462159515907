import React, { Fragment, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  StackDivider,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { StatusBadge } from "components";
import { useClipboard } from "hooks";
import { TbExternalLink } from "react-icons/tb";
import DistributorMonitoringsDetailsContext from "./context";
import { masks } from "lib";
import { FiCopy } from "react-icons/fi";

const General = () => {
  const { formData, setFormData, formErrors } = useContext(DistributorMonitoringsDetailsContext);
  const copyToClipboard = useClipboard();

  return (
    <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Status do monitoramento
        </Text>
        <StatusBadge schema="distributorMonitorings" status={formData.status} />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Fatura
        </Text>
        <Text>#{formData.invoice?.nid || "-"}</Text>
        <IconButton
          size="sm"
          variant="outline"
          as={RouterLink}
          icon={<Icon as={TbExternalLink} />}
          to={`/invoices/details/${formData.invoice?._id}`}
          target="_blank"
        />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Cliente
        </Text>
        <Box flex="1" textAlign="right">
          <Text>{formData.invoice?.customer?.name || "-"}</Text>
          <Text fontSize="xs">{masks.document(formData.invoice?.customer?.document || "-")}</Text>
        </Box>
        <IconButton
          size="sm"
          variant="outline"
          as={RouterLink}
          icon={<Icon as={TbExternalLink} />}
          to={`/customers/edit/${formData.invoice?.customer?._id}`}
          target="_blank"
        />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Telefone faturamento
        </Text>
        <Text>{masks.phone(formData.consumerUnit?.primaryBillingContact?.phone || "-")}</Text>
        <IconButton
          size="sm"
          variant="outline"
          icon={<Icon as={FiCopy} />}
          onClick={() => copyToClipboard(formData.consumerUnit?.primaryBillingContact?.phone || "-")}
        />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          E-mail faturamento
        </Text>
        <Text>{formData.consumerUnit?.primaryBillingContact?.email || "-"}</Text>
        <IconButton
          size="sm"
          variant="outline"
          icon={<Icon as={FiCopy} />}
          onClick={() => copyToClipboard(formData.consumerUnit?.primaryBillingContact?.email || "-")}
        />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Data de vencimento
        </Text>
        <Text>
          {formData.invoice?.distributor?.current ? moment(formData.invoice.distributor.current.dueDate).format("DD/MM/YYYY") : "-"}
        </Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Modalidade de fatura
        </Text>
        <Text>
          {_.isBoolean(formData.invoice?.isUnified) ? (formData.invoice.isUnified ? "Fatura unificada" : "Fatura separada") : "-"}
        </Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Código de referência
        </Text>
        <Text>{formData.referenceCode}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Data de referência
        </Text>
        <Text>{moment(formData.referenceDate).format("MM/YYYY")}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Dias decorridos desde o vencimento
        </Text>
        <Text>{formData.elapsedDays?.toLocaleString() || "-"} dias</Text>
      </HStack>

      <FormControl isInvalid={formErrors.comments}>
        <FormLabel>Observações</FormLabel>
        <Textarea
          value={formData.comments || ""}
          onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
        />
        <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
      </FormControl>

      {formData.paidAt && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Data do pagamento
          </Text>
          <Text>{moment(formData.paidAt).format("DD/MM/YYYY")}</Text>
        </HStack>
      )}

      {formData.archivedAt && (
        <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Arquivado em
            </Text>
            <Box flex="1" textAlign="right">
              <Text>{moment(formData.archivedAt).format("DD/MM/YYYY")}</Text>
              <Text fontSize="xs">
                por <strong>{formData.archivedBy?.name || "-"}</strong>
              </Text>
            </Box>
          </HStack>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Observações do arquivamento
            </Text>
            <Text flex="2">{formData.comments || "-"}</Text>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};

export default General;
