import React, { Fragment, useCallback, useContext } from "react";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { Box, Center, HStack, Icon, IconButton, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import { StatusBadge } from "components";
import { currency } from "lib";
import { MdClose } from "react-icons/md";
import { InvoicesSelect } from "./invoicesSelect";
import { TbExternalLink, TbFileInvoice } from "react-icons/tb";
import DemandLettersDetailsContext from "./context";

const Invoices = () => {
  const { formData, setFormData } = useContext(DemandLettersDetailsContext);

  const handleRemoveInvoice = useCallback((index) => {
    setFormData((state) => {
      const invoices = [...state.invoices];
      invoices.splice(index, 1);
      return { ...state, invoices };
    });
  }, []);

  return (
    <Fragment>
      <HStack justifyContent="space-between" mb={4}>
        <Text fontSize="sm" fontWeight="semibold">
          {_.size(formData.invoices)} faturas selecionadas
        </Text>
        <InvoicesSelect />
      </HStack>

      {_.size(formData.invoices) === 0 ? (
        <Center paddingTop="40px" paddingBottom="20px">
          <Box textAlign="center">
            <Icon as={TbFileInvoice} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhuma fatura selecionada
            </Text>
            <Text fontSize="sm">Ainda não foram selecionadas faturas para este parcelamento.</Text>
          </Box>
        </Center>
      ) : (
        <Table size="sm" whiteSpace="nowrap">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Nid</Th>
              <Th>Status</Th>
              <Th>Cod. referência</Th>
              <Th>N° instalação</Th>
              <Th>Dt. vencimento</Th>
              <Th>Valor Click</Th>
              <Th>#</Th>
            </Tr>
          </Thead>
          <Tbody>
            {_.map(formData.invoices, (item, index) => (
              <Tr key={item._id}>
                <Td>
                  <IconButton size="xs" colorScheme="red" icon={<Icon as={MdClose} />} onClick={() => handleRemoveInvoice(index)} />
                </Td>
                <Td>{item.nid}</Td>
                <Td>
                  <StatusBadge schema="invoices" status={item.status} />
                </Td>
                <Td>{item.referenceCode}</Td>
                <Td>{item.consumerUnit?.cemigInstallationNumber || "-"}</Td>
                <Td>{moment(item.dueDate).format("DD/MM/YYYY")}</Td>
                <Td>{currency(item.amount)}</Td>
                <Td>
                  <IconButton
                    size="sm"
                    variant="outline"
                    as={RouterLink}
                    target="_blank"
                    to={`/invoices/details/${item._id}`}
                    icon={<Icon as={TbExternalLink} />}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr fontWeight="semibold">
              <Td colSpan={6}>TOTAL</Td>
              <Td>{currency(_(formData.invoices).map("amount").sum())}</Td>
              <Td></Td>
            </Tr>
          </Tfoot>
        </Table>
      )}
    </Fragment>
  );
};

export default Invoices;
