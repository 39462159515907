import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import _ from "lodash";
import fileDownload from "js-file-download";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  SlideFade,
  Spinner,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { AsyncSelect, BoxData, Breadcrumb, ElectricityAverageCalculator, InputCurrency, PermissionedContainer, Portal } from "components";
import { DocumentHistory } from "containers";
import { useFetchData, useCustomToast, useDocumentTitle, useArrayItemHandlers, usePermissioned } from "hooks";
import { api, currency, percent, translator, yup } from "lib";
import { installationTypes, messages, statuses } from "consts";
import { MdAddCircleOutline, MdChevronLeft, MdHistory, MdMoreHoriz, MdOutlineDelete } from "react-icons/md";
import { Content } from "pages/Private/Container";
import { TbFileDownload, TbHomeBolt } from "react-icons/tb";
import { PrivateContext } from "pages/Private";

let loadUsersTimeout;

const getEconomyAmount = (powerPricePerKwh, agreedDiscount, installationType, electricityAverageSpentInKwh) => {
  const availabilityRate = { monofásico: 30, bifásico: 50, trifásico: 100 };
  const compensableConsumeInKwh = electricityAverageSpentInKwh - availabilityRate[installationType];
  const compensableAmount = powerPricePerKwh * compensableConsumeInKwh;
  return agreedDiscount * compensableAmount;
};

export const CommercialProposalsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar proposta comercial" : "Novo proposta comercial");
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(PrivateContext);
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/commercial-proposals/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const isAllowedCommercialProposalsRead = usePermissioned("commercialProposals.read");
  const toast = useCustomToast();
  const [tariffBase, isLoadingTariffBase] = useFetchData(
    useMemo(() => ({ path: "/private/tariff-bases", params: { getCurrentByType: "default_without_piscofins", isAutocomplete: true } }), [])
  );

  useEffect(() => {
    const formData = data ?? {
      status: "negotiation",
      consultant: {
        _id: currentUser._id,
        name: currentUser.name,
      },
    };
    if (!formData.powerPricePerKwh && tariffBase) formData.powerPricePerKwh = tariffBase.powerPricePerKwh;
    setFormData(formData);
  }, [data, tariffBase, currentUser]);

  useEffect(() => {
    const monthlyEconomyAmount = _(formData.consumerUnits).map("economyAmount").sum();
    setFormData((state) => ({ ...state, monthlyEconomyAmount, yearlyEconomyAmount: monthlyEconomyAmount * 12 }));
  }, [formData.consumerUnits]);

  useEffect(() => {
    setFormData((state) => ({
      ...state,
      consumerUnits: _.map(state.consumerUnits, (consumerUnit) => ({
        ...consumerUnit,
        electricityBillAmount: state.powerPricePerKwh * consumerUnit.electricityAverageSpentInKwh,
        economyAmount: getEconomyAmount(
          state.powerPricePerKwh,
          state.agreedDiscount,
          consumerUnit.installationType,
          consumerUnit.electricityAverageSpentInKwh
        ),
      })),
    }));
  }, [formData.powerPricePerKwh, formData.agreedDiscount]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id
          ? await api.patch(`/private/commercial-proposals/${_id}`, data)
          : await api.put("/private/commercial-proposals", data);
        navigate(`/commercial-proposals/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const { discountRangeStart, discountRangeEnd } = currentUser.userGroup;
      const schema = yup.object().shape({
        customerName: yup.string().required(messages.error.required),
        agreedDiscount: yup
          .number()
          .min(discountRangeStart, messages.error.greaterOrEqual.concat(percent(discountRangeStart, { precision: 0 })))
          .max(discountRangeEnd, messages.error.lessOrEqual.concat(percent(discountRangeEnd, { precision: 0 })))
          .required(messages.error.required),
        consumerUnits: yup
          .array()
          .of(
            yup.object().shape({
              installationNumber: yup.string().required(messages.error.required),
              installationType: yup.string().required(messages.error.required),
              electricityBillAmount: yup.number().required(messages.error.required),
              electricityAverageSpentInKwh: yup.number().required(messages.error.required),
            })
          )
          .min(1, messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [currentUser.userGroup, formData, handleSaveData]);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const query = { isActive: true };
      const response = await api.post("/private/users", { search, query, perPage: -1, sort: { name: 1 }, isAutocomplete: true });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleDownloadData = useCallback(
    async (mode, title) => {
      try {
        setIsDownloading(true);
        const response = await api({
          method: "post",
          url: `/private/commercial-proposals/${_id}/download/${mode}`,
          responseType: "arraybuffer",
          headers: { Accept: "application/pdf" },
        });
        fileDownload(response, `Proposta Click ${title} ${formData.customerName}.pdf`);
      } catch (arrayBufferError) {
        const error = JSON.parse(new TextDecoder().decode(arrayBufferError));
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsDownloading(false);
      }
    },
    [_id, formData.customerName]
  );

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/commercial-proposals")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/commercial-proposals", label: "propostas comerciais" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <HStack>
            <Menu>
              <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />} isLoading={isDownloading}>
                mais ações
              </MenuButton>
              <Portal>
                <MenuList fontSize="sm">
                  <MenuItem onClick={() => handleDownloadData("complete", "Detalhada")}>
                    <HStack>
                      <Icon as={TbFileDownload} />
                      <Text>baixar proposta detalhada</Text>
                    </HStack>
                  </MenuItem>
                  <MenuItem onClick={() => handleDownloadData("summary", "Resumida")}>
                    <HStack>
                      <Icon as={TbFileDownload} />
                      <Text>baixar proposta resumida</Text>
                    </HStack>
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
            {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
          </HStack>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Proposta comercial</Heading>
              {(isLoadingData || isLoadingTariffBase) && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.name : "Novo cadastro"}</Text>
          </Box>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={{ base: 12, lg: 1 }}>
            <FormControl isInvalid={formErrors.nid}>
              <FormLabel fontSize="sm">NID</FormLabel>
              <Input value={formData.nid ?? ""} isDisabled={true} />
              <FormErrorMessage>{formErrors.nid}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <FormControl isRequired={true} isInvalid={formErrors.status}>
              <FormLabel fontSize="sm">Status</FormLabel>
              <Select value={formData.status ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
                {statuses.commercialProposals.map(({ value }) => (
                  <option key={value} value={value}>
                    {translator(value)}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{formErrors.status}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.customerName}>
              <FormLabel fontSize="sm">Nome do cliente</FormLabel>
              <Input
                value={formData.customerName ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, customerName: target.value }))}
              />
              <FormErrorMessage>{formErrors.customerName}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.consultant}>
              {isAllowedCommercialProposalsRead ? (
                <>
                  <FormLabel fontSize="sm">Consultor</FormLabel>
                  <AsyncSelect
                    value={formData.consultant ?? {}}
                    defaultOptions
                    loadOptions={handleLoadUsers}
                    placeholder="Selecione o consultor"
                    onChange={(consultant) => setFormData((state) => ({ ...state, consultant }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ name }) => name}
                  />
                </>
              ) : (
                <BoxData label="Consultor" value={formData.consultant?.name ?? "-"} />
              )}
              <FormErrorMessage>{formErrors.consultant}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.agreedDiscount}>
              <FormLabel fontSize="sm">Desconto acordado</FormLabel>
              <HStack>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    value={formData.agreedDiscount ?? ""}
                    onChange={(agreedDiscount) => setFormData((state) => ({ ...state, agreedDiscount }))}
                    isPercentage={true}
                    precision="0"
                  />
                  <InputRightAddon fontSize="xs">%</InputRightAddon>
                </InputGroup>
              </HStack>
              <FormErrorMessage>{formErrors.agreedDiscount}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <BoxData label="Tarifa aplicada" value={currency(formData.powerPricePerKwh, { precision: 8 })} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <BoxData label="Economia mensal" value={currency(formData.monthlyEconomyAmount)} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <BoxData label="Economia anual" value={currency(formData.yearlyEconomyAmount)} />
          </GridItem>
        </Grid>

        <Divider my={8} />

        <HStack mb={6}>
          <Box flex="1">
            <Heading size="sm">Unidades consumidoras</Heading>
            {_.isString(formErrors.consumerUnits) && (
              <Text fontSize="sm" color="red.500">
                {formErrors.consumerUnits}
              </Text>
            )}
          </Box>
          <Button
            size="sm"
            colorScheme="main"
            variant="ghost"
            mt={4}
            leftIcon={<Icon as={MdAddCircleOutline} />}
            onClick={() =>
              handleAddArrayItem("consumerUnits", {
                installationType: "bifásico",
                electricityBillAmount: 0,
                electricityAverageSpentInKwh: 0,
                agreedDiscount: 0,
              })
            }
          >
            adicionar unidade consumidora
          </Button>
        </HStack>
        <VStack align="stretch" divider={<StackDivider />} spacing={8}>
          {_.map(formData.consumerUnits, (consumerUnit, index) => (
            <Grid key={index} templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <FormControl isRequired={true} isInvalid={formErrors.consumerUnits?.[index].installationNumber}>
                  <FormLabel fontSize="sm">Número de instalação</FormLabel>
                  <Input
                    value={consumerUnit.installationNumber ?? ""}
                    onChange={({ target }) => handleChangeArrayItem("consumerUnits", index, { installationNumber: target.value })}
                  />
                  <FormErrorMessage>{formErrors.consumerUnits?.[index].installationNumber}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 8 }}>
                <FormControl isRequired={true} isInvalid={formErrors.consumerUnits?.[index].installationNumber}>
                  <FormLabel fontSize="sm">Endereço</FormLabel>
                  <Input
                    value={consumerUnit.address ?? ""}
                    onChange={({ target }) => handleChangeArrayItem("consumerUnits", index, { address: target.value })}
                  />
                  <FormErrorMessage>{formErrors.consumerUnits?.[index].address}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.consumerUnits?.[index]?.installationType}>
                  <FormLabel fontSize="sm">Tipo de instalação</FormLabel>
                  <Select
                    value={consumerUnit.installationType ?? ""}
                    onChange={({ target }) =>
                      handleChangeArrayItem("consumerUnits", index, {
                        installationType: target.value,
                        economyAmount: getEconomyAmount(
                          formData.powerPricePerKwh,
                          formData.agreedDiscount,
                          target.value,
                          consumerUnit.electricityAverageSpentInKwh
                        ),
                      })
                    }
                  >
                    {installationTypes.map((item) => (
                      <option key={item} value={item}>
                        {item.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formErrors.consumerUnits?.[index].installationType}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 8, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.consumerUnits?.[index].electricityAverageSpentInKwh}>
                  <FormLabel fontSize="sm">Média de consumo</FormLabel>
                  <HStack>
                    <InputGroup>
                      <InputLeftElement>
                        <ElectricityAverageCalculator
                          data={consumerUnit.electricityAverageSpentInKwhMonths}
                          onChange={(electricityAverageSpentInKwhMonths, electricityAverageSpentInKwh) =>
                            handleChangeArrayItem("consumerUnits", index, {
                              electricityAverageSpentInKwhMonths,
                              electricityAverageSpentInKwh,
                              electricityBillAmount: formData.powerPricePerKwh * electricityAverageSpentInKwh,
                              economyAmount: getEconomyAmount(
                                formData.powerPricePerKwh,
                                formData.agreedDiscount,
                                consumerUnit.installationType,
                                electricityAverageSpentInKwh
                              ),
                            })
                          }
                        />
                      </InputLeftElement>
                      <Input
                        as={InputCurrency}
                        precision="0"
                        value={consumerUnit.electricityAverageSpentInKwh ?? ""}
                        onChange={(electricityAverageSpentInKwh) =>
                          handleChangeArrayItem("consumerUnits", index, {
                            electricityAverageSpentInKwh,
                            electricityBillAmount: formData.powerPricePerKwh * electricityAverageSpentInKwh,
                            economyAmount: getEconomyAmount(
                              formData.powerPricePerKwh,
                              formData.agreedDiscount,
                              consumerUnit.installationType,
                              electricityAverageSpentInKwh
                            ),
                          })
                        }
                      />
                      <InputRightElement fontSize="xs">kWh</InputRightElement>
                    </InputGroup>
                    <IconButton
                      size="md"
                      variant="outline"
                      icon={<Icon as={MdOutlineDelete} />}
                      onClick={() => handleDeleteArrayItem("consumerUnits", index)}
                    />
                  </HStack>
                  <FormErrorMessage>{formErrors.consumerUnits?.[index].electricityAverageSpentInKwh}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <BoxData label="Valor da conta" value={currency(consumerUnit.electricityBillAmount)} />
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <BoxData label="Economia mensal" value={currency(consumerUnit.economyAmount)} />
              </GridItem>
            </Grid>
          ))}
        </VStack>

        {_.size(formData.consumerUnits) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={TbHomeBolt} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma unidade consumidora
              </Text>
              <Text fontSize="sm">Ainda não foram adicionadas unidades consumidoras.</Text>
            </Box>
          </Center>
        )}
      </Content>

      <PermissionedContainer required={"commercialProposals.".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button
              size="sm"
              colorScheme="main"
              isLoading={isLoadingData || isLoadingTariffBase || isLoadingSaveData}
              onClick={handleSubmit}
            >
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate("/commercial-proposals")}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && (
        <DocumentHistory
          path={`/private/commercial-proposals/${_id}/history`}
          isOpen={isOpenDocumentHistory}
          onClose={onCloseDocumentHistory}
        />
      )}
    </>
  );
};
