import moment from "moment";
import _ from "lodash";
import { StatusBadge } from "components";
import { currency, translator } from "lib";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="installments" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "customer.name",
    title: "Cliente",
    isVisible: true,
    formatter: ({ customer }) => customer?.name,
  },
  {
    accessor: "consumerUnit.cemigInstallationNumber",
    title: "Un. consumidora",
    isVisible: true,
    formatter: ({ consumerUnit }) => consumerUnit?.cemigInstallationNumber || "-",
  },
  {
    accessor: "installmentsQtt",
    title: "Parcelas",
    isVisible: true,
    formatter: ({ installmentsQtt }) => installmentsQtt,
  },
  {
    accessor: "paidInstallmentsQtt",
    title: "Parcelas pagas",
    isVisible: true,
    formatter: ({ paidInstallmentsQtt }) => paidInstallmentsQtt,
  },
  {
    accessor: "receivableAmount",
    title: "Valor total",
    isVisible: true,
    formatter: ({ receivableAmount }) => currency(receivableAmount),
  },
  {
    accessor: "receivedAmount",
    title: "Valor recebido",
    isVisible: true,
    formatter: ({ receivedAmount }) => currency(receivedAmount),
  },
  {
    accessor: "remainingAmount",
    title: "Valor restante",
    isVisible: true,
    formatter: ({ remainingAmount }) => currency(remainingAmount),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
